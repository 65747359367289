import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PageBody, PageActions } from '@studio/legacy-components';
import { UserIdUploadModal } from '@studio/settings-panels';
import { Tooltip } from '@appcues/sonar';
import { ButtonLink } from 'studio-classic/src/components/Audience/Audiences/styled';
import useTitle from 'next/hooks/use-title';
import { ENTITLEMENTS } from 'next/entities/entitlements';
import { BeaconLink } from 'next/components/BeaconLink';
import { selectAccountFeature } from 'reducers/account/features';
import { selectUserRole } from 'reducers/account/users';
import { selectUserId } from 'reducers/user';
import IndexPage from 'components/Common/IndexPage';
import { ACCOUNT_PERMISSIONS } from 'constants/accountManagement/permissions';
import SegmentList from 'components/Audience/SegmentList';
import { uploadSegmentUserIds } from 'actions/account/segments';
import { asPromised } from 'utils/as-promised';
import {
  selectEntitlementsByName,
  selectIsEntitledByName,
} from 'selectors/entitlements-v2';

const HighlightedText = styled.span`
  font-weight: bold;
`;

export const SegmentListPage = ({
  canCreateSegments,
  hasUserIdUpload,
  onUploadSegmentUserIds,
  entitlement,
  hasEntitlement,
}) => {
  useTitle('Segments | Appcues');

  const limitContent = (
    <>
      You've reached your plan limit of{' '}
      <HighlightedText>{entitlement?.allowed_units}</HighlightedText> segment
      {entitlement?.allowed_units > 1 ? 's' : ''}.{' '}
      <BeaconLink message="Contact support" subject="Increase user limit">
        Contact support
      </BeaconLink>{' '}
      to increase the limit or you can delete an unused segment.
    </>
  );

  return (
    <IndexPage
      title="Segments"
      subHeaderActions={
        canCreateSegments ? (
          <PageActions>
            <Tooltip
              size="large"
              disabled={hasEntitlement}
              content={limitContent}
            >
              <ButtonLink
                to="/segments/new/edit"
                kind="primary"
                disabled={!hasEntitlement}
              >
                Create a Segment
              </ButtonLink>
            </Tooltip>
            {hasUserIdUpload && (
              <UserIdUploadModal
                canCreateSegments={hasEntitlement}
                onUploadSegmentUserIds={onUploadSegmentUserIds}
                limitContent={limitContent}
              />
            )}
          </PageActions>
        ) : null
      }
    >
      <PageBody>
        <SegmentList />
      </PageBody>
    </IndexPage>
  );
};

SegmentListPage.propTypes = {
  canCreateSegments: PropTypes.bool,
  hasUserIdUpload: PropTypes.bool,
  onUploadSegmentUserIds: PropTypes.func,
  entitlement: PropTypes.object,
  hasEntitlement: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    canCreateSegments:
      selectUserRole(state, selectUserId(state)) !== ACCOUNT_PERMISSIONS.EDITOR,
    hasUserIdUpload: selectAccountFeature(state, 'SEGMENT_USER_ID_UPLOAD'),
    entitlement: selectEntitlementsByName(state, ENTITLEMENTS.SEGMENTS),
    hasEntitlement: selectIsEntitledByName(state, ENTITLEMENTS.SEGMENTS),
  };
};

const mapDispatchToProps = dispatch => ({
  onUploadSegmentUserIds: body =>
    asPromised(dispatch, uploadSegmentUserIds(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SegmentListPage);
