import React from 'react';
import { Modal, Icon, Badge } from '@appcues/sonar';
import { faClockRotateLeft } from '@fortawesome/pro-solid-svg-icons/faClockRotateLeft';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import PropTypes from 'prop-types';
import { format } from 'next/lib/date';
import { VersionList, VersionListItem, Button, User, Date } from './styled';

const BADGE_COLORS = {
  Published: 'active',
  'Pushed changes': 'info',
  Unpublished: 'default',
};

export default function HistorySummaryModal({ history }) {
  return (
    <Modal.Root
      size="regular"
      trigger={
        <Button title="See publish history" iconOnly variant="tertiary">
          <Icon icon={faClockRotateLeft} />
        </Button>
      }
    >
      <Modal.Title>Publish History</Modal.Title>
      <Modal.Close>
        <Icon size="large" icon={faXmark} />
      </Modal.Close>
      <VersionList>
        {history.map(({ updatedAt, user, status }) => (
          <VersionListItem key={updatedAt}>
            <Badge variant={BADGE_COLORS[status]}>{status}</Badge>
            <User>{user.name}</User>
            <Date>{format(updatedAt, 'MMM D, YYYY h:mmA')}</Date>
          </VersionListItem>
        ))}
      </VersionList>
    </Modal.Root>
  );
}

export const HistorySummaryModalPropTypes = {
  history: PropTypes.arrayOf(
    PropTypes.objectOf({
      status: PropTypes.string,
      updatedAt: PropTypes.number,
      user: PropTypes.objectOf({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    })
  ),
};

HistorySummaryModal.propTypes = HistorySummaryModalPropTypes;
