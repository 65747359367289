import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { H2 } from '@studio/legacy-components';
import { Stack } from '@appcues/sonar';
import styled from 'styled-components';
import Loader from 'components/Common/Loader';
import { numberWithCommas } from 'utils/textFormatting';
import { ENTITLEMENTS_CAPTIONS } from 'constants/entitlements';
import {
  EntitlementsWrapper,
  SubscriptionCardWrapper,
} from 'components/Subscription/styled';
import { callApi as fetchAccountMetrics } from 'actions/accountMetrics';
import { selectAccountMetrics } from 'selectors/accountMetrics';
import { selectAccountMeta } from 'reducers/account/meta';
import { determineStatus } from 'utils/entitlements';
import EntitlementProgress from './EntitlementProgress';

const LoaderWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;

  ${Loader} {
    height: initial;
  }
`;

function CurrentEntitledPeriod({ onLoad, accountMetrics, accountMeta }) {
  useEffect(() => {
    onLoad?.();
  }, []);

  const { mtuTotal, messagesTotal } = accountMetrics;
  const { mtuLimit, messageLimit } = accountMeta;

  const messagePercent = (messagesTotal / messageLimit) * 100;
  const mtuPercent = (mtuTotal / mtuLimit) * 100;

  const messageStatus = determineStatus(messagePercent);
  const mtuStatus = determineStatus(mtuPercent);

  return (
    <SubscriptionCardWrapper kind="borderless">
      <H2 type="secondary">Your usage</H2>

      {Object.keys(accountMetrics).length === 0 ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <EntitlementsWrapper>
          <Stack spacing="regular">
            <EntitlementProgress
              title={`${numberWithCommas(mtuTotal)} of ${numberWithCommas(
                mtuLimit
              )}`}
              caption={ENTITLEMENTS_CAPTIONS.mtus}
              completePercent={mtuPercent}
              currentStatus={mtuStatus}
              showWarning={mtuStatus !== 'compliant'}
            />
            <EntitlementProgress
              title={`${numberWithCommas(messagesTotal)} of ${numberWithCommas(
                messageLimit
              )}`}
              caption={ENTITLEMENTS_CAPTIONS.messages}
              completePercent={messagePercent}
              currentStatus={messageStatus}
              showWarning={messageStatus !== 'compliant'}
            />
          </Stack>
        </EntitlementsWrapper>
      )}
    </SubscriptionCardWrapper>
  );
}

CurrentEntitledPeriod.propTypes = {
  onLoad: PropTypes.func,
  accountMetrics: PropTypes.object,
  accountMeta: PropTypes.object,
};

const mapDispatchToProps = {
  onLoad: fetchAccountMetrics,
};

const mapStateToProps = state => ({
  accountMetrics: selectAccountMetrics(state),
  accountMeta: selectAccountMeta(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentEntitledPeriod);
