/* global JOB_API_URL, APPCUES_API_URL, CUSTOMER_API_URL, IMAGE_SERVICE_URL, IMAGE_SERVICE_CACHE_URL, LISTSERV_API_URL, IMAGE_SERVICE_LAMBDA_URL */

import qs from 'qs';
import { createFetch } from 'next/lib/fetch';

// eslint-disable-next-line no-restricted-imports
import auth from 'helpers/auth-client';

import { getLocalFeature } from './features';

/**
 * Create an authenticated API client
 *
 * @return {function} Authenticated API client
 */
export function createApiClient() {
  const appcuesAPI = createFetch(`${APPCUES_API_URL}/v1`, auth);
  const customerAPI = createFetch(`${CUSTOMER_API_URL}/v1`, auth);
  const customerAPIv2 = createFetch(`${CUSTOMER_API_URL}/v2`, auth);
  const imageServiceAPI = createFetch(`${IMAGE_SERVICE_URL}/v1`, auth);
  const stepImageAPI = createFetch(`${IMAGE_SERVICE_URL}/step-image`, auth);
  const stepImageCacheAPI = createFetch(IMAGE_SERVICE_CACHE_URL, auth);
  const jobAPI = createFetch(`${JOB_API_URL}/api/batch/v3`, auth);
  const listservAPI = createFetch(`${LISTSERV_API_URL}/v1`, auth);
  const imageLambdaService = createFetch(`${IMAGE_SERVICE_LAMBDA_URL}`, auth);

  return {
    /**
     * Analytics API
     */
    getAnalytics: async payload => {
      const accountId = await auth.getAccountId();
      const body = {
        account_id: accountId,
        ...payload,
        meta: {
          ...payload.meta,
          // we are temporarily adding this to the meta object to allow for testing
          // the migration of Analytics v2 queries from snowflake to clickhouse
          force_clickhouse: getLocalFeature('FORCE_CLICKHOUSE'),
        },
      };
      return customerAPIv2(`accounts/${accountId}/analytics/query`, {
        account: false,
        method: 'POST',
        body,
      });
    },

    getGoalsReachedFromExperienceStarted: async payload => {
      const accountId = await auth.getAccountId();
      return customerAPI(
        `accounts/${accountId}/analytics/query?${qs.stringify(payload)}`,
        {
          account: false,
        }
      );
    },

    /**
     * Appcues API
     */
    getProfileAttributes: () => appcuesAPI(`profile_attributes`),

    getEventNames: () => appcuesAPI(`event_names`),

    getGroupAttributes: () => appcuesAPI(`group_attributes`),

    /**
     * Customer API - Flows
     */
    getFlows: () => customerAPI('flows?summary=true'),
    getFlow: id => customerAPI(`flows/${id}`),
    createFlow: data => customerAPI('flows', { method: 'POST', body: data }),
    updateFlow: (id, delta) =>
      customerAPI(`flows/${id}`, { method: 'PATCH', body: delta }),

    cloneFlow: id => customerAPI(`flows/${id}/clone`, { method: 'POST' }),
    deleteFlow: id => customerAPI(`flows/${id}`, { method: 'DELETE' }),
    archiveFlow: id => customerAPI(`flows/${id}/archive`, { method: 'POST' }),
    unarchiveFlow: id =>
      customerAPI(`flows/${id}/unarchive`, { method: 'POST' }),
    createTestFlow: id => customerAPI(`flows/${id}/test`, { method: 'POST' }),

    createStepGroups: (flowId, stepGroup) =>
      customerAPI(`flows/${flowId}/stepGroups`, {
        method: 'POST',
        body: stepGroup,
      }),
    createStepChild: ({ id, type, groupId, child }) => {
      return customerAPI(
        `flows/${id}/stepGroups/${type}/${groupId}/stepChildren`,
        { method: 'POST', body: child }
      );
    },
    getTrialStatus: () => customerAPI(`/trial_status`),

    /**
     * Customer API - Pins
     *
     * NOTE: These endpoints _may_ become more generic as we move towards
     *       grouping all of these as experiences.
     */
    getPins: () => customerAPI('experiences?type=persistent'),

    getPin: id => customerAPI(`experiences/${id}`),

    createPin: data =>
      customerAPI('experiences?template=persistent', {
        method: 'POST',
        body: data,
      }),

    revertPin: id =>
      customerAPI(`experiences/${id}/revert`, { method: 'POST' }),

    updatePin: (id, delta) =>
      customerAPI(`experiences/${id}`, { method: 'PATCH', body: delta }),

    publishPin: id =>
      customerAPI(`experiences/${id}/publish`, { method: 'POST' }),

    unpublishPin: id =>
      customerAPI(`experiences/${id}/unpublish`, { method: 'POST' }),

    clonePin: id => customerAPI(`experiences/${id}/clone`, { method: 'POST' }),

    deletePin: id => customerAPI(`experiences/${id}`, { method: 'DELETE' }),

    exportAnalytics: async data => {
      const accountId = await auth.getAccountId();
      return jobAPI(`csv`, {
        method: 'POST',
        body: { ...data, account_id: accountId },
        account: false,
      });
    },

    getLocales: () => customerAPI(`locales`),

    localizeFlow: flowId =>
      customerAPI(`flows/${flowId}/localize`, { method: 'POST' }),

    getTranslationMetaForFlow: flowId =>
      customerAPI(`flows/${flowId}/translations/meta`),

    uploadTranslation: (flowId, localeId, translation) => {
      const formData = new FormData();
      formData.append('locale_id', localeId);
      formData.append('file', translation);
      return customerAPI(`flows/${flowId}/translations`, {
        method: 'POST',
        contentType: 'multipart/form-data',
        form: formData,
      });
    },

    downloadTranslations: (flowId, format) =>
      customerAPI(`flows/${flowId}/translations/download?format=${format}`, {
        responseType: 'blob',
        method: 'GET',
        contentType: 'application/zip',
      }),

    deleteTranslation: (flowId, translationId) =>
      customerAPI(`flows/${flowId}/translations/${translationId}`, {
        method: 'DELETE',
      }),

    delocalizeFlow: flowId =>
      customerAPI(`flows/${flowId}/delocalize`, { method: 'POST' }),

    /**
     * Customer API - Experiences
     */
    getExperiences: () => customerAPI('experiences?summary=true'),

    getExperience: id => customerAPI(`experiences/${id}`),

    getVersionedExperience: (id, version) =>
      customerAPI(`experiences/${id}/versions/${version}`),

    createExperience: ({ template, ...data }) =>
      customerAPI(`experiences?template=${template}`, {
        method: 'POST',
        body: data,
      }),

    updateExperience: (id, delta) =>
      customerAPI(`experiences/${id}`, { method: 'PATCH', body: delta }),

    deleteExperience: id =>
      customerAPI(`experiences/${id}`, { method: 'DELETE' }),

    cloneExperience: (id, data) =>
      customerAPI(`experiences/${id}/clone`, { method: 'POST', body: data }),

    publishExperience: id =>
      customerAPI(`experiences/${id}/publish`, { method: 'POST' }),

    publishFlow: id => customerAPI(`flows/${id}/publish`, { method: 'POST' }),

    revertExperience: id =>
      customerAPI(`experiences/${id}/revert`, { method: 'POST' }),

    revertFlow: id => customerAPI(`flows/${id}/revert`, { method: 'POST' }),

    unpublishExperience: id =>
      customerAPI(`experiences/${id}/unpublish`, { method: 'POST' }),

    unpublishFlow: id =>
      customerAPI(`flows/${id}/unpublish`, { method: 'POST' }),

    archiveExperience: id =>
      customerAPI(`experiences/${id}/archive`, { method: 'POST' }),

    unarchiveExperience: id =>
      customerAPI(`experiences/${id}/unarchive`, { method: 'POST' }),

    getExperienceVersions: id => customerAPI(`experiences/${id}/versions`),

    /**
     * Customer API - Apps
     */
    getApps: () => customerAPI('apps'),

    getApp: id => customerAPI(`apps/${id}`),

    createApp: data => customerAPI('apps', { method: 'POST', body: data }),

    updateApp: (id, delta) =>
      customerAPI(`apps/${id}`, { method: 'PATCH', body: delta }),

    /**
     * Customer API - Goals
     */
    getGoals: () => customerAPI('goals'),

    /**
     * Customer API - Rules
     */
    getRules: () => customerAPI('rules'),

    getRule: id => customerAPI(`rules/${id}`),

    updateRule: (id, delta) =>
      customerAPI(`rules/${id}`, { method: 'PATCH', body: delta }),

    getPublishedRules: () => customerAPI('rules?sorted=true'),

    getSegments: () => customerAPI('segments'),
    createSegment: data =>
      customerAPI('segments', { method: 'POST', body: data }),

    /**
     * Customer API - Content Status
     */
    getContentStatus: (id, type) => customerAPI(`${type}/${id}/content-status`),

    /**
     * Customer API - Profile attribute labels
     */
    getProfileAttributeLabels: () => customerAPI('profile_attributes/labels'),

    /**
     * Customer API - Users
     */
    getUser: id => customerAPI(`users/${id}`, { account: false }),
    updateUser: (id, delta) =>
      customerAPI(`users/${id}`, {
        account: false,
        method: 'PATCH',
        body: delta,
      }),

    /**
     * Customer API - Accounts
     */
    getAccounts: userId =>
      customerAPI(`users/${userId}/accounts`, { account: false }),

    getAccount: () => customerAPIv2(''),

    updateAccount: delta => customerAPIv2('', { method: 'PATCH', body: delta }),

    getAccountUsers: () => customerAPI(`users`),
    getAccountUser: id => customerAPI(`users/${id}`),
    updateAccountUser: (id, delta) =>
      customerAPI(`users/${id}`, {
        method: 'PATCH',
        body: delta,
      }),

    getChecklists: () => customerAPI('checklists?state=draft'),

    getThemes: () => customerAPI('themes'),

    getEventNameLabels: () => customerAPI('event_labels'),

    getInvitations: () => customerAPI('invites'),

    /**
     * Customer API - Accounts Users
     */
    inviteTeamMember: invitation =>
      customerAPI('invites', {
        method: 'POST',
        body: invitation,
      }),

    /**
     * Customer API - Tags
     */
    getTags: () => customerAPI('tags'),

    createTag: data => customerAPI('tags', { method: 'POST', body: data }),

    updateTag: (id, delta) =>
      customerAPI(`tags/${id}`, { method: 'PATCH', body: delta }),

    deleteTag: id => customerAPI(`tags/${id}`, { method: 'DELETE' }),

    /**
     * Customer API - Survey Responses
     */
    getSurveyResponses: (id, start, end, contentType, propertyNames = []) =>
      customerAPI('/graphql', {
        account: false,
        method: 'POST',
        body: {
          query: `query surveyAnswers($id: String!, $page: Page, $sort: [SurveyAnswerSortOrder!], $filter: SurveyFilter, $propertyNames: [String]) {
            surveyAnswers(id: $id, page: $page, sort: $sort, filter: $filter, propertyNames: $propertyNames) {
              answers {
                userId
                sessionId
                userProfileAttributes
                timestamp
                fieldAnswers {
                  fieldId
                  value
                }
                userProfileAttributes
              }
              labels {
                fieldId
                label
                stepChildId
                stepChildNumber
                customReportingLabel
              }
              totalAnswersCount
              totalAnswersInRangeCount
            }
          }`,
          operationName: 'surveyAnswers',
          variables: {
            id,
            page: {
              size: 1000,
            },
            filter: {
              start,
              end,
            },
            sort: {
              key: 'TIMESTAMP',
              direction: 'DESC',
            },
            propertyNames,
          },
        },
      }),

    /**
     * Customer API - Users
     */
    getUserProfiles: (
      propertyNames,
      sortKey = '_lastSeenAt',
      direction = 'DESC',
      limit = 5,
      offset = 0
    ) =>
      customerAPI('/graphql', {
        account: false,
        method: 'POST',
        body: {
          query: `query GetEndUserProfileList($filter: ProfileFilter, $page: PageInput!, $sort: ProfileListSort) {
            endUserProfileList(
              profilesPage: $page,
              filter: $filter,
              sort: $sort
            ) {
              profiles
              properties {
                name
                label
              }
              profilesPageInfo {
                limit
                total
                offset
              }
            }
          }
        `,
          operationName: 'userProfiles',
          variables: {
            sort: {
              sortKey,
              direction,
            },
            page: {
              limit,
              offset,
            },
            filter: {
              propertyNames,
            },
          },
        },
      }),

    /**
     * Customer API - Event User Profiles
     */
    getEventUserProfiles: ({
      eventNames,
      contentId,
      contentType,
      startTime,
      endTime,
      propertyNames,
      direction = 'DESC',
      limit = 10,
      offset = 0,
      searchString,
    }) =>
      customerAPI('/graphql', {
        account: false,
        method: 'POST',
        body: {
          query: `query EndUserProfileListForEvent(
            $filter: ProfileForEventFilter
            $sort: ProfileForEventListSort
            $page: PageInput!
          ) {
            endUserProfileListForEvent(
              filter: $filter
              sort: $sort
              page: $page
            ) {
              results {
                accountId
                userId
                profile
                event {
                  timestamp
                  name
                  contentName
                  stepId
                  stepName
                  stepType
                  url
                }
              }
              pageInfo {
                limit
                total
                offset
              }
            }
          }
        `,
          variables: {
            sort: {
              direction,
            },
            page: {
              limit,
              offset,
            },
            filter: {
              eventNames,
              contentId,
              contentType,
              startTime,
              endTime,
              propertyNames,
              searchString,
            },
          },
        },
      }),

    getGroups: (
      propertyNames,
      sortKey = '_lastSeenAt',
      direction = 'DESC',
      limit = 5,
      offset = 0
    ) =>
      customerAPI('/graphql', {
        account: false,
        method: 'POST',
        body: {
          query: `query groupProfileList(
            $filter: ProfileFilter
            $profilesPage: PageInput!
            $sort: ProfileListSort
          ) {
            groupProfileList(
              profilesPage: $profilesPage
              filter: $filter
              sort: $sort
            ) {
              profiles
              properties {
                name
                label
              }
              profilesPageInfo {
                limit
                total
                offset
              }
            }
          }
          `,
          operationName: 'groupProfileList',
          variables: {
            sort: {
              sortKey,
              direction,
            },
            page: {
              limit,
              offset,
            },
            filter: {
              propertyNames,
            },
          },
        },
      }),

    /**
     * Customer API - Scheduling
     */
    getExperienceSchedules: contentId =>
      customerAPI('/graphql', {
        account: false,
        method: 'POST',
        body: {
          query: `query Schedules($contentId: String!) {
            schedules(contentId: $contentId) {
              id
              startDate
              endDate
              contentId
            }
          }`,
          operationName: 'Schedules',
          variables: { contentId },
        },
      }),
    deleteExperienceSchedule: id =>
      customerAPI('/graphql', {
        account: false,
        method: 'POST',
        body: {
          query: `mutation DeleteSchedule($id: String!) {
            deleteSchedule(id: $id) {
              success
              message
            }
          }`,
          operationName: 'DeleteSchedule',
          variables: { id },
        },
      }),
    createExperienceSchedule: input =>
      customerAPI('/graphql', {
        account: false,
        method: 'POST',
        body: {
          query: `mutation CreateSchedule($input: ScheduleInput!) {
            createSchedule(input: $input) {
              id
              startDate
              endDate
              contentId
            }
          }`,
          operationName: 'CreateSchedule',
          variables: { input },
        },
      }),
    /**
     * Image Service API
     */
    purgeImage: path => stepImageAPI(path),

    presignImage: path => stepImageAPI(path, { responseType: 'text' }),

    getPresignedImage: path =>
      stepImageCacheAPI(path, { account: false, responseType: 'blob' }),

    getExperienceImage: (id, stepId, state = 'DRAFT', version) => {
      const queryParams = { state };

      if (state === 'VERSIONED') {
        queryParams.versionId = version;
      }

      return imageServiceAPI(
        `experiences/${id}/steps/${stepId}?${qs.stringify(queryParams)}`,
        {
          responseType: 'blob',
        }
      );
    },
    getLambdaExperienceImage: async (id, stepId) => {
      const accountId = await auth.getAccountId();
      const queryParams = {
        experienceId: id,
        experienceStepId: stepId,
        accountId,
      };

      return imageLambdaService(`/?${qs.stringify(queryParams)}`, {
        responseType: 'blob',
        account: false,
      });
    },
    getLambdaImage: async ({
      flowId,
      flowStepId,
      flowStepChildId,
      localeId,
    }) => {
      const accountId = await auth.getAccountId();
      const queryParams = {
        accountId,
        flowId,
        flowStepId,
        flowStepChildId,
        localeId,
        ...(localeId ? { latest: true } : {}),
      };

      return imageLambdaService(`/?${qs.stringify(queryParams)}`, {
        responseType: 'blob',
        account: false,
      });
    },

    /**
     * Listserv endpoints
     */

    getInstalledDomains: () => listservAPI('domains'),

    getSuggestions: (type, attributeName, term) =>
      customerAPI('/graphql', {
        account: false,
        method: 'POST',
        body: {
          query: /* GraphQL */ `
            query GetSuggestions(
              $type: SuggestionType
              $attributeName: String!
              $term: String!
            ) {
              suggestions(
                type: $type
                attributeName: $attributeName
                term: $term
              )
            }
          `,
          operationName: 'GetSuggestions',
          variables: { type, attributeName, term },
        },
      }),

    /*
    Note: The groupProfileAttributes query uses a limit of 1500 because that is the
    maximum number of group attributes that listserv allows an account to create and the
    groupProfileAttributes query requires that a limit is provided.
    */
    getTargetingOptions: () =>
      customerAPI('/graphql', {
        account: false,
        method: 'POST',
        body: {
          query: /* GraphQL */ `
            query GetTargetingOptions {
              flows(filter: { states: [DRAFT] }) {
                id
                name
                steps {
                  stepType
                  id
                  index
                }
              }
              pins: experiences(filter: { types: [PERSISTENT] }) {
                name
                id
                steps {
                  name
                  id
                }
              }
              banners: experiences(filter: { types: [BANNER] }) {
                id
                name
                steps {
                  id
                }
              }
              launchpads: experiences(filter: { types: [LAUNCHPAD] }) {
                id
                name
                steps {
                  id
                }
              }
              mobile: experiences(filter: { types: [MOBILE, MOBILE_EMBED] }) {
                id
                name
                type
                app {
                  id
                }
              }
              emails {
                id
                name
                subject
              }
              pushNotifications {
                id
                name
                content
              }
              segments {
                id
                name
              }
              eventNames {
                name
              }
              profileAttributes(filter: { showInUi: true }) {
                name
              }
              groupProfileAttributes(
                filter: { showInUi: true }
                profileAttributesPage: { limit: 1500, offset: 0 }
              ) {
                attributes {
                  name
                }
              }
              checklists {
                id
                internalName
              }
            }
          `,
          operationName: 'GetTargetingOptions',
        },
      }),
    /**
     * Customer API - Entitlements
     */

    getEntitlements: () => customerAPIv2('entitlements'),
  };
}
