import styled from 'styled-components';
import { MenuList, Text } from '@studio/legacy-components';

export const DropdownListHeading = styled(Text)`
  padding: 8px 12px;
`;

export const OptionList = styled(MenuList)`
  width: 218px;
`;

export const TooltipLabel = styled.div`
  width: 200px;
  white-space: initial;
`;

export const HighlightedText = styled.span`
  font-weight: bold;
`;
