import React, { useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import useToggle from 'next/hooks/use-toggle';
import { openExperience } from 'next/lib/builder';
import { OpenInBuilderManager } from 'next/components/OpenInBuilder';
import { selectUser } from 'next/entities/users';
import useTitle from 'next/hooks/use-title';

import Header, { HeaderPropTypes } from 'next/components/Header';
import { selectExperience } from 'next/entities/experiences';
import ExperienceContext from 'next/components/ExperienceContext';
import { selectSchedule } from 'next/entities/schedules';
import { selectExperienceHistory } from 'next/entities/experience-versions';
import { selectFeature, LAUNCHPADS_V2_ANALYTICS } from 'next/entities/features';

const ExperienceHeader = ({
  id,
  name,
  state,
  previewUrl,
  published,
  createdAt,
  createdByName,
  updatedAt,
  updatedByName,
  renderActions,
  currentPage = 'settings',
  schedule,
  history,
  hasLaunchpadAnalyticsEnabled,
}) => {
  const isPublishedAndSettings = currentPage === 'settings' && published;

  useTitle(
    `${name} | ${
      currentPage === 'settings' ? 'Settings' : 'Analytics'
    } | Appcues`
  );
  const [builderModalVisible, toggleBuilderModal] = useToggle();
  const { type, routePath } = useContext(ExperienceContext);

  const handleOpenInBuilder = useCallback(() => {
    openExperience({ experience: id, url: previewUrl, pattern: type });
  }, [id, previewUrl, type]);

  const capitalizedContext = useMemo(() => {
    return type.charAt(0).toUpperCase() + type.slice(1);
  }, [type]);

  return (
    <>
      <Header
        id={id}
        name={name}
        state={state}
        published={published}
        returnUrl={
          isPublishedAndSettings &&
          (routePath !== '/launchpads' || hasLaunchpadAnalyticsEnabled)
            ? `${routePath}/${id}/analytics`
            : routePath
        }
        title={isPublishedAndSettings ? 'Analytics' : capitalizedContext}
        createdAt={createdAt}
        createdByName={createdByName}
        updatedAt={updatedAt}
        updatedByName={updatedByName}
        schedule={schedule}
        history={history}
        renderActions={() =>
          renderActions?.({
            onClickOpenInBuilder: toggleBuilderModal,
            updatedAt,
            published,
          })
        }
      />
      <OpenInBuilderManager
        onClose={toggleBuilderModal}
        onNavigate={handleOpenInBuilder}
        type={type}
        visible={builderModalVisible}
      />
    </>
  );
};

ExperienceHeader.propTypes = {
  ...HeaderPropTypes,
  id: PropTypes.string,
  previewUrl: PropTypes.string,
  renderActions: PropTypes.func,
  hasLaunchpadAnalyticsEnabled: PropTypes.bool,
};

const mapStateToProps = (state, { id }) => {
  const {
    published,
    state: experienceState,
    updatedAt,
    updatedBy,
    createdAt,
    createdBy,
    name,
    previewUrl,
  } = selectExperience(state, id) || {};
  const createdByUser = selectUser(state, createdBy);
  const updatedByUser = selectUser(state, updatedBy);

  return {
    id,
    name,
    state: experienceState,
    published,
    createdAt,
    createdByName: createdByUser?.fullname,
    updatedAt,
    updatedByName: updatedByUser?.fullname,
    previewUrl,
    schedule: selectSchedule(state),
    history: selectExperienceHistory(state, id),
    hasLaunchpadAnalyticsEnabled: selectFeature(state, LAUNCHPADS_V2_ANALYTICS),
  };
};

export default connect(mapStateToProps)(ExperienceHeader);
