/* eslint-disable @appcues/jsx-props-no-spreading */
import React from 'react';
import {
  Modal,
  Button,
  Icon,
  Form,
  FormField,
  Label,
  Text,
  Radio,
  Stack,
  Row,
  Tooltip,
  Select,
  SelectOption,
  InlineMessage,
  DatePicker,
  Alert,
} from '@appcues/sonar';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import isEmpty from 'lodash.isempty';

import { publishedEntitlementShape } from 'next/entities/entitlements';
import { format, parse } from 'next/lib/date';
import { BeaconLink } from 'next/components/BeaconLink';

import { ScheduleType } from './types';
import { timeSlots, DATE_FORMAT, TIME_FORMAT } from './dates';
import { getValidateEndDate, getValidateStartDate } from './validation';
import {
  DateContainer,
  TimeZoneTag,
  DateFormField,
  TimeFormField,
  HighlightedText,
  StyledRadioItem,
  StyledTooltip,
} from './styled';

const getInitialValues = (schedule, isPublishedEntitled = true) => {
  if (isEmpty(schedule)) {
    return {
      startOption: isPublishedEntitled ? 'schedule' : 'publish',
      startDate: '',
      startTime: undefined,
      endOption: 'schedule',
      endDate: '',
      endTime: undefined,
    };
  }

  return {
    startOption: schedule?.startDate ? 'schedule' : 'publish',
    startDate: schedule?.startDate
      ? new Date(format(schedule?.startDate, DATE_FORMAT))
      : '',
    startTime: schedule?.startDate
      ? format(schedule?.startDate, TIME_FORMAT)
      : undefined,
    endOption: schedule?.endDate ? 'schedule' : 'unpublish',
    endDate: schedule?.endDate
      ? new Date(format(schedule?.endDate, DATE_FORMAT))
      : '',
    endTime: schedule?.endDate
      ? format(schedule?.endDate, TIME_FORMAT)
      : undefined,
  };
};

export const SchedulingModal = ({
  contentId,
  schedule,
  onCreate,
  onRemove,
  toggleModal,
  experienceType = 'Flow',
  published = false,
  publishedEntitlement,
  isPublishedEntitled = true,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    getValues,
    trigger,
  } = useForm({
    defaultValues: getInitialValues(schedule, isPublishedEntitled),
  });

  const onSubmit = data => {
    const shouldScheduleStartDate = data.startOption === 'schedule';
    const shouldScheduleEndDate = data.endOption === 'schedule';

    const payload = {
      contentId,
      enabled: true,
    };

    if (shouldScheduleStartDate && !published) {
      payload.startDate = parse(
        `${format(data.startDate, DATE_FORMAT)} ${data.startTime}`
      ).toISOString();
    }

    if (shouldScheduleEndDate) {
      payload.endDate = parse(
        `${format(data.endDate, DATE_FORMAT)} ${data.endTime}`
      ).toISOString();
    }

    if (shouldScheduleEndDate || shouldScheduleStartDate) {
      onCreate(payload);
    } else {
      onRemove();
    }

    toggleModal();
  };

  const handleStartOptionChange = value => {
    setValue('startOption', value);
  };

  const handleEndOptionChange = value => {
    setValue('endOption', value);
  };

  const today = new Date();

  return (
    <Modal.Root modal={false} open>
      <Modal.Title>
        <Stack spacing="small">
          Schedule
          <Text>
            Set dates to automatically publish and/or unpublish this{' '}
            {experienceType}.
          </Text>
        </Stack>
      </Modal.Title>

      <Modal.Close onClick={toggleModal}>
        <Icon size="large" icon={faXmark} />
      </Modal.Close>

      <Row spacing="regular" yAlign="center">
        <TimeZoneTag>
          <Icon icon={faGlobe} />
          <Text size="regular" weight="bold" colorToken="#000">
            {new Intl.DateTimeFormat().resolvedOptions().timeZone}
          </Text>
        </TimeZoneTag>

        <Tooltip
          delayDuration={0}
          content={
            <Text size="regular" colorToken="white">
              Times and time zones are based on your browser’s settings.
            </Text>
          }
        >
          <Icon icon={faQuestionCircle} colorToken="foreground-link" />
        </Tooltip>
      </Row>

      {published && (
        <Alert variant="info">
          <Alert.Title>
            Unpublish the {experienceType} to set a start date
          </Alert.Title>
        </Alert>
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Text size="regular" weight="bold">
          Starts
        </Text>

        <FormField inline>
          <Radio.Group
            {...register('startOption')}
            defaultValue={watch('startOption')}
            onValueChange={handleStartOptionChange}
            disabled={published}
          >
            <Radio.Item
              id="publish"
              value="publish"
              label="When I publish"
              disabled={published}
            />
            <div>
              <StyledTooltip
                size="regular"
                disabled={isPublishedEntitled}
                content={
                  <>
                    You've reached your plan limit of{' '}
                    <HighlightedText>
                      {publishedEntitlement?.allowed_units}
                    </HighlightedText>{' '}
                    published message
                    {publishedEntitlement?.allowed_units > 1 ? 's' : ''}.{' '}
                    <BeaconLink>Contact support</BeaconLink> to increase the
                    limit or you can unpublish a message.
                  </>
                }
              >
                <StyledRadioItem
                  id="start-schedule"
                  value="schedule"
                  label="At a specific date/time"
                  disabled={published || !isPublishedEntitled}
                />
              </StyledTooltip>
            </div>
            {watch('startOption') === 'schedule' && (
              <DateContainer>
                <Row>
                  <DateFormField>
                    <Label htmlFor="startDate">Date</Label>
                    <DatePicker
                      fromYear={today.getFullYear()}
                      toYear={today.getFullYear() + 10}
                      fromMonth={today}
                      fromDay={today}
                      {...register('startDate', {
                        validate: getValidateStartDate(getValues, published),
                      })}
                      initialValue={watch('startDate')}
                      onChange={newStartDate => {
                        setValue('startDate', newStartDate, {
                          shouldValidate: true,
                        });
                      }}
                      inputProps={{
                        error: errors.startDate,
                        id: 'startDate',
                        disabled: published,
                      }}
                    />
                  </DateFormField>
                  <TimeFormField>
                    <Label htmlFor="startTime">Time</Label>
                    <Select
                      id="startTime"
                      placeholder="Select a time..."
                      size="regular"
                      width="full-width"
                      {...register('startTime')}
                      defaultValue={watch('startTime')}
                      onValueChange={value => {
                        setValue('startTime', value);
                        trigger(['startDate', 'startTime']);
                      }}
                      error={errors.startDate}
                      height={327}
                      disabled={published}
                    >
                      {timeSlots.map(time => (
                        <SelectOption
                          key={time.label}
                          label={time.label}
                          value={time.value}
                        />
                      ))}
                    </Select>
                  </TimeFormField>
                </Row>
                {errors.startDate && (
                  <Row>
                    <InlineMessage variant="error">
                      {errors.startDate.message}
                    </InlineMessage>
                  </Row>
                )}
              </DateContainer>
            )}
          </Radio.Group>
        </FormField>

        <Text size="regular" weight="bold">
          Ends
        </Text>
        <FormField inline>
          <Radio.Group
            {...register('endOption')}
            defaultValue={watch('endOption')}
            onValueChange={handleEndOptionChange}
          >
            <Radio.Item
              id="unpublish-option"
              value="unpublish"
              label="When I unpublish"
            />
            <Radio.Item
              id="schedule-option"
              value="schedule"
              label="At a specific date/time"
            />
            {watch('endOption') === 'schedule' && (
              <DateContainer>
                <Row>
                  <DateFormField>
                    <Label htmlFor="endDate">Date</Label>

                    <DatePicker
                      fromYear={today.getFullYear()}
                      toYear={today.getFullYear() + 10}
                      fromMonth={today}
                      fromDay={today}
                      {...register('endDate', {
                        validate: getValidateEndDate(getValues),
                      })}
                      initialValue={watch('endDate')}
                      onChange={newEndDate => {
                        setValue('endDate', newEndDate, {
                          shouldValidate: true,
                        });
                      }}
                      inputProps={{
                        error: errors.endDate,
                        id: 'endDate',
                      }}
                    />
                  </DateFormField>
                  <TimeFormField>
                    <Label htmlFor="endTime">Time</Label>
                    <Select
                      id="endTime"
                      placeholder="Select a time..."
                      size="regular"
                      width="full-width"
                      {...register('endTime')}
                      defaultValue={watch('endTime')}
                      onValueChange={value => {
                        setValue('endTime', value);
                        trigger(['endDate', 'endTime']);
                      }}
                      error={errors.endDate}
                      height={327}
                    >
                      {timeSlots.map(time => (
                        <SelectOption
                          key={time.label}
                          label={time.label}
                          value={time.value}
                        />
                      ))}
                    </Select>
                  </TimeFormField>
                </Row>
                {errors.endDate && (
                  <Row>
                    <InlineMessage variant="error">
                      {errors.endDate.message}
                    </InlineMessage>
                  </Row>
                )}
              </DateContainer>
            )}
          </Radio.Group>
        </FormField>
        <Modal.Actions>
          <Button variant="tertiary" onClick={toggleModal}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </Modal.Actions>
      </Form>
    </Modal.Root>
  );
};

SchedulingModal.propTypes = {
  contentId: PropTypes.string.isRequired,
  schedule: ScheduleType,
  onCreate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  experienceType: PropTypes.string,
  published: PropTypes.bool,
  publishedEntitlement: publishedEntitlementShape,
  isPublishedEntitled: PropTypes.bool,
};
