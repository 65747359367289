import React, { useState } from 'react';
import { Button, Tooltip, Link } from '@appcues/sonar';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import styled from 'styled-components';

import { isAfter } from 'next/lib/date';
import { add, remove, selectSchedules } from 'next/entities/schedules';
import {
  selectEntitlementsByName,
  ENTITLEMENTS,
  selectIsEntitledByName,
  publishedEntitlementShape,
} from 'next/entities/entitlements';
import { selectIsInstalled } from 'next/entities/installed';
import { ScheduleType } from './types';
import { SchedulingModal } from './SchedulingModal';

// We know this is not good
// We know this is not good
// but needed since we are using a sonar button with classic buttons
// to avoid alignment issues
const StyledButton = styled(Button)`
  max-height: 40px;
`;

// This container is only needed to force useForm to unmount correctly
// hence avoiding showing stale data.
// Other strategies we've tried:
// - forceUnregister configuration, which doesn't solve the problem
// - A key prop on the modal container, still the useForm was showing stale data
// - A useEffect cleaning the data, which caused more undesired side-effects than solving the issue
// - The container which was the solution that works the best and is the least intrusive
export const SchedulingModalProvider = ({
  schedule,
  installed,
  experienceType,
  disabled,
  publishedEntitlement,
  isPublishedEntitled,
  ...modalProps
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasSchedule = !isEmpty(schedule);

  const toggleModal = () => {
    setIsOpen(open => !open);
  };

  return (
    <>
      <Tooltip
        disabled={
          disabled || installed || experienceType === 'Mobile Experience'
        }
        size="regular"
        content={
          <>
            To schedule your experiences to publish, you'll have to install
            Appcues directly into your codebase. Don't worry...it's super easy.{' '}
            <Link
              activeColorToken="foreground-tooltip-link-active"
              colorToken="foreground-tooltip-link"
              href="/settings/installation"
              size="small"
            >
              Click here to learn how.
            </Link>
          </>
        }
      >
        <StyledButton
          disabled={
            disabled || (!installed && experienceType !== 'Mobile Experience')
          }
          experienceType={experienceType}
          variant="tertiary"
          onClick={toggleModal}
        >
          {hasSchedule && 'Edit'} Schedule
        </StyledButton>
      </Tooltip>
      {isOpen && (
        <SchedulingModal
          // eslint-disable-next-line @appcues/jsx-props-no-spreading
          {...modalProps}
          experienceType={experienceType}
          schedule={schedule}
          toggleModal={toggleModal}
          publishedEntitlement={publishedEntitlement}
          isPublishedEntitled={isPublishedEntitled}
        />
      )}
    </>
  );
};

SchedulingModalProvider.propTypes = {
  contentId: PropTypes.string.isRequired,
  schedule: ScheduleType,
  onCreate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  published: PropTypes.bool,
  installed: PropTypes.bool,
  experienceType: PropTypes.string,
  disabled: PropTypes.bool,
  publishedEntitlement: publishedEntitlementShape,
  isPublishedEntitled: PropTypes.bool,
};

const mapStateToProps = state => {
  const [schedule] = Object.values(selectSchedules(state));
  const areDatesInTheFuture =
    isAfter(schedule?.startDate, new Date()) ||
    isAfter(schedule?.endDate, new Date());

  return {
    schedule: areDatesInTheFuture ? schedule : {},
    installed: selectIsInstalled(state),
    publishedEntitlement: selectEntitlementsByName(
      state,
      ENTITLEMENTS.PUBLISHED_EXPERIENCES
    ),
    isPublishedEntitled: selectIsEntitledByName(
      state,
      ENTITLEMENTS.PUBLISHED_EXPERIENCES
    ),
  };
};

const mapDispatchToProps = {
  onCreate: add,
  onRemove: remove,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchedulingModalProvider);
