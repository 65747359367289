import { Stack, Text, Button } from '@appcues/sonar';
import styled from 'styled-components';
import ChecklistSVG from 'next/assets/images/checklist.svg';

export const StyledConfigurationCard = styled(Stack)`
  background-color: var(--background-level-1);
  padding: var(--spacing-regular) var(--spacing-large);
  border-radius: var(--border-radius-small);
  width: 100%;
  box-shadow: var(--elevation-100);
`;

export const StyledHeading = styled(Text)`
  font-size: var(--font-size-large);
`;

export const ChecklistImage = styled.img.attrs({
  src: ChecklistSVG,
})`
  width: 160px;
`;

export const StyledButton = styled(Button)`
  margin-top: 16px;
  display: inline-block;
  text-decoration: none;
  font-size: unset;
`;
