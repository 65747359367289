import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import { Caption, Text } from '@studio/legacy-components';
import { format } from 'next/lib/date';
import { createInvoicePreview } from 'actions/newBilling';
import LoadingIcon from 'assets/images/checkout-loading.svg';
import { Plan as PlanShape } from 'components/Subscription/shape';
import { selectInvoicePreview } from 'reducers/newBilling';
import { selectBillingDetails } from 'reducers/billing';
import { selectAccountMeta } from 'reducers/account/meta';
import { asPromised } from 'utils/as-promised';
import { NEW_PLANS } from 'constants/plans';
import { formatCurrency, formatNumber, haveValuesChanged } from 'utils/billing';
import {
  StyledHeading,
  StyledText,
  CheckoutRow,
  DueTodayText,
  IntervalInfo,
  LoadingSpinner,
  NextInvoiceInfo,
} from './styled';

export const ExistingSubscriptionCheckout = ({
  billingDetails,
  completedForms,
  editingBillingDetails,
  entitlementsProcessed,
  formValues,
  invoicePreview,
  onLoad,
  planData,
  selectedEntitlements,
  selectedEntitlementsCount,
  stripeId,
}) => {
  const [loading, setLoading] = useState(false);
  const [prevBillingAddress, setPrevBillingAddress] = useState({});
  const isNewPlan = NEW_PLANS.includes(planData?.id);
  const loadSubscriptionData = useCallback(async () => {
    const billingAddress = completedForms.billing
      ? {
          line1: formValues.line1,
          country: formValues.country.value,
          postalCode: formValues.postalCode,
          city: formValues.city,
        }
      : {
          line1: billingDetails.metadata.Address_Line1,
          country: billingDetails.metadata.Address_Country,
          postalCode: billingDetails.metadata.Address_PostalCode,
          city: billingDetails.metadata.Address_City,
        };

    if (
      (editingBillingDetails && !completedForms.billing) ||
      !haveValuesChanged(billingAddress, prevBillingAddress)
    )
      return;

    const formattedAddons = Object.keys(selectedEntitlements)
      .map(addon => ({
        [addon]: {
          purchased_units:
            entitlementsProcessed[addon].purchasedUnits +
            selectedEntitlements[addon],
          unit_price: entitlementsProcessed[addon].unitPrice,
        },
      }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {});

    setLoading(true);

    await onLoad?.({
      stripeId,
      billingAddress,
      addOns: formattedAddons,
    });

    setPrevBillingAddress(billingAddress);
    setLoading(false);
  }, [completedForms, billingDetails]);

  useEffect(() => {
    loadSubscriptionData();
  }, [loadSubscriptionData]);

  return (
    <>
      <StyledHeading>Appcues {planData?.name ?? ''}</StyledHeading>

      <StyledText noMargin={selectedEntitlementsCount > 0}>
        {isNewPlan
          ? `Up to ${
              formatNumber(planData?.planLimit) ?? ''
            } Monthly tracked users (MTUs)`
          : `Up to ${
              formatNumber(planData?.planLimit) ?? ''
            } Monthly active users (MAUs)`}
      </StyledText>

      {isNewPlan && (
        <StyledText>
          Up to {formatNumber(Number(planData?.planLimit) * 5) ?? ''} Monthly
          messages
        </StyledText>
      )}

      {selectedEntitlementsCount > 0 && (
        <StyledText>
          + {selectedEntitlementsCount}{' '}
          {pluralize('add-ons', selectedEntitlementsCount)}
        </StyledText>
      )}

      <CheckoutRow layout="vertical">
        <IntervalInfo spaced={planData.planInterval === 'yearly'}>
          <Text>Prorated charge today</Text>
          {loading ? (
            <LoadingSpinner src={LoadingIcon} alt="stripe icon" />
          ) : (
            <Text bold>
              {invoicePreview ? formatCurrency(invoicePreview.proration) : '-'}
            </Text>
          )}
        </IntervalInfo>
      </CheckoutRow>

      {invoicePreview && invoicePreview.taxes !== 0 && !loading && (
        <CheckoutRow>
          <Text>Sales tax</Text>
          <Text bold>{formatCurrency(invoicePreview.taxes) || '-'}</Text>
        </CheckoutRow>
      )}

      <CheckoutRow borderless>
        <DueTodayText bold>Due Today</DueTodayText>
        <DueTodayText bold>
          {invoicePreview && !loading
            ? formatCurrency(invoicePreview.proration + invoicePreview.taxes)
            : '-'}
        </DueTodayText>
      </CheckoutRow>

      <NextInvoiceInfo>
        <Text>
          <Caption>
            Today we'll charge you{' '}
            {invoicePreview ? formatCurrency(invoicePreview.proration) : '-'}{' '}
            for the cost of your new plan minus credit for the time remaining on
            your existing plan.
          </Caption>
        </Text>

        {invoicePreview && (
          <Text>
            <Caption>
              We will bill you {formatCurrency(invoicePreview.total)} plus tax
              at the start of your next{' '}
              {planData.planInterval.replace('yearly', 'annual')} billing period
              on{' '}
              {format(invoicePreview.next_payment_attempt * 1000, 'MM/DD/YYYY')}
              .
            </Caption>
          </Text>
        )}

        <Text>
          <Caption>
            Accounts that exceed their {isNewPlan ? 'MTU or Messages ' : 'MAU '}
            limit{isNewPlan ? 's' : ''} will be upgraded for the next billing
            period.
          </Caption>
        </Text>
      </NextInvoiceInfo>
    </>
  );
};

ExistingSubscriptionCheckout.propTypes = {
  billingDetails: PropTypes.object,
  completedForms: PropTypes.object,
  editingBillingDetails: PropTypes.bool,
  entitlementsProcessed: PropTypes.object,
  formValues: PropTypes.object,
  invoicePreview: PropTypes.object,
  onLoad: PropTypes.func,
  planData: PlanShape,
  selectedEntitlements: PropTypes.object,
  selectedEntitlementsCount: PropTypes.number,
  stripeId: PropTypes.string,
};

const mapStateToProps = state => ({
  billingDetails: selectBillingDetails(state),
  invoicePreview: selectInvoicePreview(state),
  stripeId: selectAccountMeta(state).stripeId,
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload => asPromised(dispatch, createInvoicePreview(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExistingSubscriptionCheckout);
