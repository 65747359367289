import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import hash from 'object-hash';
import styled from 'styled-components';

import {
  Button,
  Notice,
  NOTICE_TYPES,
  CPage,
} from '@appcues/component-library';
import {
  getFlattenedClausesFromNestedConditions,
  getNestedConditionsFromFlattenedClauses,
  clauseKeys,
} from '@appcues/libcues';
import { Icon } from '@studio/legacy-components';

import { DocumentTitle } from 'next/hooks/use-title';
import Panel from 'components/Common/Panel';
import Loader from 'components/Common/Loader';
import ProgressBar from 'components/Common/ProgressBar';
import Publishing from 'components/Common/Publishing';
import ContentEditable from 'components/Common/ContentEditable';
import SatisfactionStyleEdit from 'components/SatisfactionSurveys/SatisfactionSurveysEdit/Style';
import SatisfactionContentEdit from 'components/SatisfactionSurveys/SatisfactionSurveysEdit/Content';
import SatisfactionAudienceEdit from 'components/SatisfactionSurveys/SatisfactionSurveysEdit/Audience';
import SatisfactionSummaryStep from 'components/SatisfactionSurveys/SatisfactionSurveysEdit/Summary';
import SatisfactionSurveyEditPreview from 'components/SatisfactionSurveys/SatisfactionSurveysEdit/SurveyEditPreview';
import SatisfactionSurveyLiveTest from 'components/SatisfactionSurveys/SatisfactionSurveysEdit/SurveyLiveTest';

import * as conditionsActions from 'actions/account/conditions';
import * as metaActions from 'actions/account/meta';
import { update as updateRule } from 'actions/account/rules';
import * as satisfactionActions from 'actions/satisfaction';
import * as currentModalActions from 'actions/currentModal';
import * as satisfactionHelpers from 'helpers/satisfaction';

import { STEP_TYPE_IDS } from 'constants/stepTypes';
import {
  SATISFACTION_CREATION_STEP_TITLES,
  SATISFACTION_CREATION_STEPS,
  SATISFACTION_BASE_CONDITIONS,
  defaultQuestionOne,
  defaultQuestionTwo,
} from 'constants/satisfaction/data';

import { navigate } from 'actions/routing';
import { trackEvent } from 'actions/events';

import {
  selectAccountMeta,
  selectAccountMetaSynced,
} from 'reducers/account/meta';
import {
  selectAccountRule,
  selectAccountRulesSynced,
} from 'reducers/account/rules';
import {
  selectAccountSegments,
  selectAccountSegmentsSynced,
} from 'reducers/account/segments';
import {
  selectAccountConditionsSynced,
  selectAccountStepConditions,
} from 'reducers/account/conditions';

import {
  selectSatisfactionState,
  selectAccountSatisfactionSurvey,
} from 'selectors/satisfaction';
import { selectIsInstalled } from 'reducers/account/installedDomains';
import { selectUserRole } from 'reducers/account/users';
import { selectUserId } from 'reducers/user';
import { publishNPS } from 'helpers/publishing-api';
import { startPolling, stopPolling } from 'actions/account/flowStatus';
import { selectFlows, selectStepGroups } from 'reducers/account/flows';
import { selectFlowStatus } from 'reducers/account/flowStatus';
import { selectAccountChecklists } from 'entities/checklists';
import {
  selectEntitlementsByName,
  selectIsEntitledByName,
} from 'selectors/entitlements-v2';

export class SatisfactionSurveysEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      satisfactionId: props.satisfactionId,
      name: '',
      error: null,
      clauses: null,
      rule: null,
      currentStep: 0,
      isTestMode: false,
    };

    this.updateSatisfaction = this.updateSatisfaction.bind(this);
  }

  componentDidMount() {
    const { actions, satisfactionId } = this.props;
    actions.startPolling(satisfactionId);
    // eslint-disable-next-line new-cap
    this.UNSAFE_componentWillReceiveProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      satisfaction,
      rule: firebaseRule,
      conditions,
      loaded,
      actions,
    } = nextProps;
    const {
      satisfactionId,
      rule: localRule,
      hasContentInitialized,
    } = this.state;

    if (
      loaded &&
      satisfactionId !== 'new' &&
      satisfaction &&
      Array.isArray(conditions)
    ) {
      const hasPublishedStateChanged =
        localRule && localRule.published !== firebaseRule.published;
      if (hasPublishedStateChanged) {
        const wasNPSJustPublished =
          !localRule.published && firebaseRule.published;
        if (wasNPSJustPublished) {
          actions.navigate('/nps');
        } else {
          this.setState({ rule: firebaseRule });
        }
      }

      const firebaseNPSSteps =
        satisfaction && satisfaction.steps && Object.values(satisfaction.steps);
      if (
        firebaseNPSSteps &&
        (!hasContentInitialized || this.hasContentChanged())
      ) {
        const firstStep = firebaseNPSSteps.find(step => step.index === 0);
        const secondStep = firebaseNPSSteps.find(step => step.index === 1);
        if (firstStep && secondStep) {
          const { backgroundColor, foregroundColor } = firstStep;

          this.setState({
            name: satisfaction.name,
            questionOne: firstStep.questionText,
            questionTwo: secondStep.questionText,
            autoCollapseFeedbackForm:
              satisfactionHelpers.getAutoCollapseSetting(firstStep),
            backgroundColor,
            foregroundColor,
            rule: firebaseRule,
            clauses: conditions,
            hasContentInitialized: true,
            isNewSatisfaction: false,
          });
        }
      } else if (
        hasContentInitialized &&
        this.haveConditionsChanged(conditions)
      ) {
        this.setState({ clauses: conditions });
      }
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.stopPolling();
  }

  setStep(step) {
    const { actions } = this.props;

    actions.toggleSurveyPreview(true);

    this.setState({
      error: null,
      currentStep: step,
      isTestMode: false,
    });
  }

  showError = msg => {
    this.setState({ error: msg });
  };

  saveSatisfaction = (newClauses, newTheme) => {
    const { actions, satisfaction } = this.props;
    const {
      name,
      backgroundColor,
      foregroundColor,
      questionOne = defaultQuestionOne,
      questionTwo = defaultQuestionTwo,
      isNewSatisfaction,
      satisfactionId,
      rule,
      autoCollapseFeedbackForm,
      clauses,
    } = this.state;

    if (!name) {
      this.setState({
        error: 'Please enter a name for your NPS survey.',
      });
    } else {
      const content = {
        name,
        backgroundColor:
          (newTheme && newTheme.backgroundColor) || backgroundColor,
        foregroundColor:
          (newTheme && newTheme.foregroundColor) || foregroundColor,
        questionOne,
        questionTwo,
        autoCollapseFeedbackForm,
      };

      const conditions =
        clauses ||
        getFlattenedClausesFromNestedConditions(SATISFACTION_BASE_CONDITIONS);

      if (isNewSatisfaction) {
        actions.createSatisfactionWithRule(rule, conditions);
      } else if (!isNewSatisfaction && satisfaction) {
        if (newTheme || this.hasContentChanged()) {
          actions.updateAndFlushSatisfaction(satisfactionId, content);
        }

        if (newClauses || this.haveConditionsChanged()) {
          actions.updateConditions(satisfactionId, newClauses || conditions);
          actions.updateRule(satisfactionId, rule);
        }

        this.setState({ error: null });
      }
    }
  };

  updateSatisfaction = changes =>
    new Promise(res => {
      this.setState({ ...changes }, res);
    });

  hasContentChanged = () => {
    const { satisfaction } = this.props;
    const {
      name,
      backgroundColor,
      foregroundColor,
      questionOne,
      questionTwo,
      autoCollapseFeedbackForm,
    } = this.state;

    if (!satisfaction) return true;
    if (!name) return false;

    const steps = (satisfaction && Object.values(satisfaction.steps)) || [];
    const firstStep = steps.find(step => step.index === 0) || {};
    const secondStep = steps.find(step => step.index === 1) || {};
    const firebaseContent = {
      name: satisfaction ? satisfaction.name : '',
      backgroundColor: firstStep.backgroundColor,
      foregroundColor: firstStep.foregroundColor,
      questionOne: firstStep.questionText,
      questionTwo: secondStep.questionText,
      autoCollapseFeedbackForm:
        satisfactionHelpers.getAutoCollapseSetting(firstStep),
    };

    const localContent = {
      name,
      backgroundColor,
      foregroundColor,
      questionOne,
      questionTwo,
      autoCollapseFeedbackForm,
    };

    return hash(localContent) !== hash(firebaseContent);
  };

  updateSatisfactionName(name) {
    const { actions, satisfactionId } = this.props;
    actions.updateAndFlushSatisfaction(satisfactionId, { name });
    this.setState({ error: null, name });
  }

  isStep(stepIndex) {
    const { currentStep } = this.state;
    return currentStep === SATISFACTION_CREATION_STEPS.indexOf(stepIndex);
  }

  isFirstStep() {
    const { currentStep } = this.state;
    const firstStepIndex = 0;

    return currentStep === firstStepIndex;
  }

  isLastStep() {
    const { currentStep } = this.state;
    const lastStepIndex = SATISFACTION_CREATION_STEPS.length - 1;

    return currentStep === lastStepIndex;
  }

  haveConditionsChanged(newClauses) {
    const { conditions: firebaseConditions } = this.props;
    const { clauses: localConditions } = this.state;

    if (!localConditions) return false;

    const nestedLocalConditions = getNestedConditionsFromFlattenedClauses(
      localConditions,
      null,
      clauseKeys
    );
    const nestedFirebaseConditions = getNestedConditionsFromFlattenedClauses(
      newClauses || firebaseConditions,
      null,
      clauseKeys
    );

    const haveConditionsChanged =
      hash(nestedLocalConditions) !== hash(nestedFirebaseConditions);
    return haveConditionsChanged;
  }

  toggleTestMode() {
    const { isTestMode } = this.state;
    this.setState({ isTestMode: !isTestMode });
  }

  render() {
    const {
      actions,
      meta,
      isInstalled,
      steps,
      segments,
      satisfaction,
      loaded,
      className,
      rule,
      contentStatus,
      satisfactionState,
      role,
      checklists,
      isEntitledToPublish,
      publishedEntitlement,
    } = this.props;
    const {
      clauses: initial,
      satisfactionId,
      name,
      currentStep,
      backgroundColor,
      foregroundColor,
      questionOne,
      questionTwo,
      isNewSatisfaction,
      error,
      hasContentInitialized,
      isTestMode,
      autoCollapseFeedbackForm,
    } = this.state;

    const clauses = initial || [];
    if (!loaded || !hasContentInitialized) return <Loader key="loader" />;

    const isPublished = rule && rule.published;
    const lastUpdatedAtTimestamp = satisfaction && satisfaction.updatedAt;

    return (
      <CPage.Container>
        <DocumentTitle title="NPS Survey Settings | Appcues" />
        <div className={`${className} satisfaction-header`}>
          <Panel>
            <div className="satisfaction-controls">
              <ContentEditable
                className="satisfaction-name"
                html={name}
                onBlur={e => this.updateSatisfactionName(e.target.textContent)}
                placeholder="Name your survey"
              />
              <ProgressBar
                className="satisfaction-steps"
                progressSteps={SATISFACTION_CREATION_STEPS}
                currentStep={currentStep}
                onClick={step => this.setStep(step)}
              />
            </div>
          </Panel>
          {error && <Notice type={NOTICE_TYPES.warning}>{error}</Notice>}
          {this.isStep(SATISFACTION_CREATION_STEP_TITLES.STYLE) && (
            <SatisfactionStyleEdit
              satisfactionId={satisfactionId}
              backgroundColor={backgroundColor}
              foregroundColor={foregroundColor}
              satisfaction={satisfaction}
              isNewSatisfaction={isNewSatisfaction}
              updateAndFlushSatisfaction={actions.updateAndFlushSatisfaction}
              toggleSurveyPreview={collapsePreview =>
                actions.toggleSurveyPreview(collapsePreview)
              }
              trackEvent={actions.trackEvent}
            />
          )}

          {this.isStep(SATISFACTION_CREATION_STEP_TITLES.CONTENT) && (
            <SatisfactionContentEdit
              satisfactionId={satisfactionId}
              questionOne={questionOne || defaultQuestionOne}
              questionTwo={questionTwo || defaultQuestionTwo}
              autoCollapseFeedbackForm={autoCollapseFeedbackForm}
              saveSatisfaction={this.saveSatisfaction}
              updateSatisfaction={this.updateSatisfaction}
              toggleSurveyPreview={(collapsePreview, tab) =>
                actions.toggleSurveyPreview(collapsePreview, tab)
              }
              setAutoCollapseFeedbackSetting={
                actions.setAutoCollapseFeedbackSetting
              }
              trackEvent={actions.trackEvent}
            />
          )}

          {this.isStep(SATISFACTION_CREATION_STEP_TITLES.AUDIENCE) && (
            <SatisfactionAudienceEdit
              actions={actions}
              meta={meta}
              isInstalled={isInstalled}
              steps={steps}
              rule={rule}
              clauses={clauses}
              satisfactionId={satisfactionId}
              saveSatisfaction={this.saveSatisfaction}
              updateSatisfaction={this.updateSatisfaction}
              showError={msg => this.showError(msg)}
              toggleSurveyPreview={collapsePreview =>
                actions.toggleSurveyPreview(collapsePreview)
              }
              trackEvent={actions.trackEvent}
            />
          )}
          {this.isStep(SATISFACTION_CREATION_STEP_TITLES.SUMMARY) && (
            <SatisfactionSummaryStep
              backgroundColor={backgroundColor}
              foregroundColor={foregroundColor}
              questionOne={questionOne}
              questionTwo={questionTwo}
              meta={meta}
              rule={rule}
              steps={steps}
              segments={segments}
              clauses={clauses}
              updateSatisfaction={this.updateSatisfaction}
              toggleSurveyPreview={collapsePreview =>
                actions.toggleSurveyPreview(collapsePreview)
              }
              trackEvent={actions.trackEvent}
              checklists={checklists}
            />
          )}

          <div className="button-navigation-row">
            {(this.isFirstStep() || this.isLastStep()) && (
              <Button onClick={() => actions.navigate('/nps')}>
                Return to Dashboard
              </Button>
            )}
            {!this.isFirstStep() && !this.isLastStep() && (
              <Button
                className="button-success"
                onClick={() => this.setStep(currentStep - 1)}
              >
                <LeftArrow />
                Back: {SATISFACTION_CREATION_STEPS[currentStep - 1]}
              </Button>
            )}
            {!this.isLastStep() && (
              <Button
                className="button-success"
                onClick={() => this.setStep(currentStep + 1)}
              >
                Next: {SATISFACTION_CREATION_STEPS[currentStep + 1]}
                <RightArrow />
              </Button>
            )}
            {this.isLastStep() && (
              <Button
                onClick={() => this.toggleTestMode()}
                className={`button-primary test-button ${
                  isTestMode ? 'test-mode-on' : ''
                }`}
              >
                Test it out
              </Button>
            )}
            {!isNewSatisfaction && this.isLastStep() && (
              <Publishing
                contentStatus={contentStatus}
                isInstalled={isInstalled}
                isPublished={isPublished}
                publishToApi={publishNPS}
                stepId={satisfactionId}
                stepType={STEP_TYPE_IDS.SATISFACTION}
                lastUpdatedAtTimestamp={lastUpdatedAtTimestamp}
                role={role}
                isEntitledToPublish={isEntitledToPublish}
                publishedEntitlement={publishedEntitlement}
              />
            )}
          </div>

          {hasContentInitialized && !isTestMode && (
            <SatisfactionSurveyEditPreview
              isTestMode={isTestMode}
              meta={meta}
              currentStep={currentStep}
              cancelTestMode={() => {
                this.setState({ isTestMode: false });
              }}
              previewTab={satisfactionState.previewTab}
              collapsePreview={satisfactionState.collapsePreview}
              toggleSurveyPreview={(collapsePreview, tab) =>
                actions.toggleSurveyPreview(collapsePreview, tab)
              }
              satisfaction={satisfaction}
              backgroundColor={backgroundColor}
              foregroundColor={foregroundColor}
              questionOne={questionOne || defaultQuestionOne}
              questionTwo={questionTwo || defaultQuestionTwo}
            />
          )}
          {hasContentInitialized && isTestMode && (
            <SatisfactionSurveyLiveTest
              meta={meta}
              satisfaction={satisfaction}
            />
          )}
        </div>
      </CPage.Container>
    );
  }
}

function mapStateToProps(state, routeProps) {
  const { satisfactionId } = routeProps.match.params;
  const satisfactionState = selectSatisfactionState(state);
  const satisfaction = satisfactionId
    ? selectAccountSatisfactionSurvey(state, satisfactionId)
    : null;

  return {
    satisfactionId,
    satisfaction,
    satisfactionState,
    meta: selectAccountMeta(state),
    isInstalled: selectIsInstalled(state),
    steps: {
      ...selectFlows(state),
      ...selectStepGroups(state),
    },
    rule: selectAccountRule(state, satisfactionId),
    segments: selectAccountSegments(state),
    contentStatus: selectFlowStatus(state, satisfactionId),
    conditions: selectAccountStepConditions(state, satisfactionId),
    role: selectUserRole(state, selectUserId(state)),
    checklists: selectAccountChecklists(state),
    loaded:
      selectAccountMetaSynced(state) &&
      selectAccountRulesSynced(state) &&
      selectAccountSegmentsSynced(state) &&
      selectAccountConditionsSynced(state),
    isEntitledToPublish: selectIsEntitledByName(state, 'PUBLISHED_EXPERIENCES'),
    publishedEntitlement: selectEntitlementsByName(
      state,
      'PUBLISHED_EXPERIENCES'
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...conditionsActions,
        ...metaActions,
        ...satisfactionActions,
        ...currentModalActions,
        navigate,
        trackEvent,
        updateRule,
        startPolling,
        stopPolling,
      },
      dispatch
    ),
  };
}
const StyledSatisfactionSuverysEdit = styled(SatisfactionSurveysEdit)`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 60px;
  position: relative;

  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;

  .panel {
    display: flex;
    justify-content: space-between;

    .panel-body {
      width: 100%;
    }

    hr {
      margin: 1rem 0;
    }
  }

  .satisfaction-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .satisfaction-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .satisfaction-name {
      max-width: 575px;
      flex: 1;
    }
    .satisfaction-steps {
      flex: 1;
    }
  }

  .satisfaction-form {
    .satisfaction-title,
    .satisfaction-header,
    .satisfaction-body {
      width: 100%;
    }
  }

  .satisfaction-form-block {
    .field-group-fields {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .satisfaction-form-card {
        width: 80%;
        margin: auto;
        cursor: default;

        hr {
          margin: 1rem -1.25rem;
        }

        &:hover {
          cursor: default;
        }

        .satisfaction-card-top {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          p:first-child {
            margin-top: 15px;
            margin-bottom: 0px;
          }

          .pages-urls-description,
          .audience-days-description {
            margin-top: 11px;
            margin-bottom: 0px;
          }

          .button {
            position: absolute;
            top: 15px;
            right: 15px;
          }
        }
      }

      main,
      textarea {
        width: 100%;
      }

      textarea {
        margin-top: 15px;
      }

      .metadata-item + .metadata-item:before {
        content: none;
      }
    }
  }

  hr {
    height: 0;
    border: none;
    border-bottom: 2px solid ${props => props.theme['$gray-1']};
    margin: 1.25rem 0 2rem;
  }

  .button-navigation-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 100px;

    .test-button {
      margin-left: 10px;

      &.test-mode-on {
        background-color: ${props => props.theme['$purple-light']};
        color: white;
        border-color: white;
      }
    }

    & > *:first-child i {
      margin-right: 0.75rem;
    }
    & > *:last-child i {
      margin-left: 0.75rem;
    }

    .publish-bar {
      flex-grow: 0;
      > .publish-buttons-container {
        padding-top: 0px;
      }
    }

    & > *:last-child {
      margin-left: 10px;
    }
  }

  .audience {
    .audience-targeting {
      > div {
        line-height: 1.42857;
        font-size: 16px;
        font-weight: 400;
        padding: 0px;

        .option {
          padding: 0px;
        }

        .radio {
          color: #848484;
          margin: 4px 0px;

          input {
            margin: 5px 10px 5px 0;
          }
        }
      }
    }

    input[type='text'] {
      color: rgb(30, 30, 30);
    }
  }

  .sdk-script-container {
    position: fixed;
    bottom: 0px;
    left: 0px;
    height: 155px;
    width: 100%;
    border: none;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 1999;
  }

  .hsbeacon-cover {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 80px;
    background-color: white;
    z-index: 1055;
  }
`;

const LeftArrow = styled(Icon).attrs(() => ({
  icon: 'arrow-left',
}))`
  margin-right: 0.75rem;
`;

const RightArrow = styled(Icon).attrs(() => ({
  icon: 'arrow-right',
}))`
  margin-left: 0.75rem;
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledSatisfactionSuverysEdit);
