import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  FULL_RANGE_OPTIONS,
  Page,
  PageBody,
  Select,
  Spinner,
} from '@studio/legacy-components';
import useDateRangeQuery from 'next/hooks/use-date-range-query';
import { BoundedDateRangeDropdown } from 'next/components/BoundedDateRange';
import { Control, Controls, ControlSeparator } from 'next/components/Listing';
import PinHeader from 'next/components/PinHeader';
import TimestampRefresh from 'next/components/TimestampRefresh';
import { selectExperienceHistoricalLimit } from 'next/lib/selectors';
import { selectSegmentTargetingOptions } from 'next/lib/selectors-options';
import { clear } from 'next/entities/analytics';
import {
  PIN_ANALYTICS,
  PIN_ANALYTICS_SEGMENT_FILTER,
  PIN_ISSUES,
  selectFeature,
} from 'next/entities/features';
import { replace as replacePage } from 'next/entities/page';
import { RecentUsersNewProvider } from 'next/components/RecentUsers';
import { selectPin } from 'next/entities/pins';
import NotPublished from 'next/components/NotPublished/NotPublished';
import { AnalyticsHeaderActions } from 'next/components/ExperienceHeader';
import ComingSoon from './ComingSoon';
import PinStats from './PinStats';
import ActivityChart from './ActivityChart';
import Issues from './Issues';
import { LoadingWrapper } from './styled';

export const AnalyticsPage = ({
  id,
  onLoad,
  hasPinsAnalytics,
  hasSegmentFilter,
  hasPinIssues,
  limit,
  createdAt,
  segmentsOptions = [],
  previewUrl,
  onRefresh,
  published,
  loaded,
}) => {
  const [segmentId, setSegmentId] = useState(null);
  const [updated, setUpdated] = useState(Date.now());

  const handleRefresh = () => {
    onRefresh();
    setUpdated(Date.now());
  };

  const selected = segmentsOptions.find(option => option.value === segmentId);

  const [{ range, start, end }, setDateRange] = useDateRangeQuery(
    FULL_RANGE_OPTIONS,
    {
      range: 7,
    }
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  if (!loaded) {
    return (
      <LoadingWrapper>
        <Spinner aria-label="Loading" />
      </LoadingWrapper>
    );
  }

  return (
    <Page>
      <PinHeader
        currentPage="analytics"
        id={id}
        renderActions={({ onClickOpenInBuilder }) => (
          <AnalyticsHeaderActions
            id={id}
            onClickOpenInBuilder={onClickOpenInBuilder}
            showExport={hasPinsAnalytics}
            createdAt={createdAt}
          />
        )}
      />
      <PageBody>
        {hasPinsAnalytics ? (
          published ? (
            <>
              <Controls>
                <Control>
                  <BoundedDateRangeDropdown
                    limit={limit}
                    onApply={setDateRange}
                    options={FULL_RANGE_OPTIONS}
                    value={{ range, start, end }}
                  />
                </Control>
                {hasSegmentFilter && (
                  <Control>
                    <Select
                      icon="users"
                      onChange={option => setSegmentId(option?.value ?? null)}
                      options={segmentsOptions}
                      placeholder="Select a segment..."
                      value={selected}
                      isClearable
                    />
                  </Control>
                )}
                <ControlSeparator />
                <Control>
                  <TimestampRefresh endTime={updated} onClick={handleRefresh} />
                </Control>
              </Controls>
              <ActivityChart
                startTime={start.getTime()}
                endTime={end.getTime()}
                updated={updated}
                segmentId={segmentId}
                id={id}
              />
              <PinStats
                id={id}
                startTime={start.getTime()}
                endTime={end.getTime()}
                previewUrl={previewUrl}
                segmentId={segmentId}
                updated={updated}
              />
              {hasPinIssues && (
                <Issues
                  id={id}
                  startTime={start.getTime()}
                  endTime={end.getTime()}
                  previewUrl={previewUrl}
                  segmentId={segmentId}
                  updated={updated}
                />
              )}
              <RecentUsersNewProvider
                id={id}
                endTime={end.toISOString()}
                startTime={start.toISOString()}
                limit={10}
                type="Pin"
              />
            </>
          ) : (
            <NotPublished
              title="Track Pin performance"
              description="View key performance metrics about this Pin experience."
              action={{
                link: `/pins/${id}/settings`,
                label: 'Publish Pin',
              }}
            />
          )
        ) : (
          <ComingSoon />
        )}
      </PageBody>
    </Page>
  );
};

AnalyticsPage.propTypes = {
  id: PropTypes.string,
  onLoad: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      pinId: PropTypes.string,
    }),
  }),
  hasPinsAnalytics: PropTypes.bool,
  hasSegmentFilter: PropTypes.bool,
  hasPinIssues: PropTypes.bool,
  limit: PropTypes.number,
  createdAt: PropTypes.number,
  segmentsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  previewUrl: PropTypes.string,
  onRefresh: PropTypes.func,
  loaded: PropTypes.bool,
  published: PropTypes.bool,
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      path,
      params: { pinId: id },
    },
  }
) => ({
  onLoad: () => dispatch(replacePage({ path, id })),
  onRefresh: () => dispatch(clear()),
});

const mapStateToProps = (
  state,
  {
    match: {
      params: { pinId: id },
    },
  }
) => {
  const pin = selectPin(state, id) || {};

  return {
    id,
    hasPinsAnalytics: selectFeature(state, PIN_ANALYTICS),
    hasSegmentFilter: selectFeature(state, PIN_ANALYTICS_SEGMENT_FILTER),
    hasPinIssues: selectFeature(state, PIN_ISSUES),
    limit: selectExperienceHistoricalLimit(state),
    createdAt: pin.createdAt || 0,
    segmentsOptions: selectSegmentTargetingOptions(state),
    previewUrl: pin.previewUrl,
    loaded: Boolean(selectPin(state, id)),
    published: Boolean(pin.publishedAt),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsPage);
