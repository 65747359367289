import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CPage, CRow } from '@appcues/component-library';
import styled from 'styled-components';
import moment from 'moment';
import { Button, DateRangeDropdown } from '@studio/legacy-components';
import TimestampRefresh from 'next/components/TimestampRefresh';
import useQueryString from 'next/hooks/use-query-string';
import { BoundedDateRangeDropdown } from 'next/components/BoundedDateRange';
import useDateRangeQuery from 'next/hooks/use-date-range-query';
import useTitle from 'next/hooks/use-title';
import { clear } from 'actions/analytics-v2';
import { getChart, update } from 'actions/account/charts';
import { selectChart } from 'reducers/account/charts';
import { selectAccountUser } from 'reducers/account/users';
import { selectAccountMetaSynced } from 'reducers/account/meta';
import { selectAccountFeaturesSynced } from 'reducers/account/features';
import { selectDateRangeOptions } from 'selectors/timeframe-options';
import Loader from 'components/Common/Loader';
import IndexPage from 'components/Common/IndexPage';
import SelectSegmentDropDown from 'components/Common/SelectSegmentDropDown';
import DeprecatedChart from 'components/Insights/Explorer/Chart/DeprecatedChart';
import EventsByUserTable from 'components/Insights/Charts/View/EventsByUserTable';
import { ChartExportManager } from 'components/Insights/Common/ExportManager';
import SegmentWarningNotice from 'components/nps/dashboard/SegmentWarningNotice';

// NOTE: Sigh... remove the negative margin in CRow making it impossible to span
//       the full width properly...
const PageContainer = styled(CPage.Container)`
  ${CRow} {
    margin-left: 0;
    margin-right: 0;
  }
`;

const StyledCRow = styled(CRow)`
  align-items: center;
  margin-top: 10px;

  ${DateRangeDropdown} {
    margin-right: 12px;
  }
`;

export const ChartView = ({
  chart,
  userName = '',
  options = [],
  onRefresh,
  onSave,
}) => {
  const [updated, setUpdated] = useState(Date.now());

  const [{ range, start, end }, setDateRange] = useDateRangeQuery(options, {
    start: chart.start_time && new Date(chart.start_time),
    end: chart.end_time && new Date(chart.end_time),
    range:
      chart.mode === 'TRAILING'
        ? Number.parseInt(chart.trailing_days, 10)
        : 'custom',
  });

  const [chartType, setChartType] = useQueryString('ct', chart.type, {
    validate: val => ['line', 'column'].includes(val),
  });

  const [segmentId, setSegmentId] = useQueryString('si', chart.segment_id);

  const handleSaveChart = () => {
    // FIXME: Current and previous quarter will be saved as fixed dates since
    //        trailing days expects a number of days and we have no way to store
    //        other types of ranges
    const dates =
      typeof range === 'number'
        ? { mode: 'TRAILING', trailing_days: range }
        : { mode: 'FIXED', start_time: start, end_time: end };

    onSave({ ...dates, segment_id: segmentId, type: chartType });
  };

  const handleRefresh = () => {
    setUpdated(Date.now());
    onRefresh();
  };

  const startTime = start.getTime();
  const endTime = end.getTime();

  const timestamp = chart.created_at
    ? moment(chart.created_at).format('ll')
    : '-';

  const changed =
    chart.mode === 'FIXED'
      ? new Date(chart.start_time).valueOf() !== startTime ||
        new Date(chart.end_time).valueOf() !== endTime
      : chart.trailing_days !== String(range);

  const dirty =
    changed || chart.type !== chartType || chart.segment_id !== segmentId;

  return (
    <IndexPage
      title={chart.name}
      description={`Created by ${userName} ${timestamp}`}
      subHeaderActions={
        <>
          <ChartExportManager
            chartId={chart.id}
            trailingDays={range}
            endDate={endTime}
            segmentId={segmentId}
          />
          <Button onClick={handleSaveChart} disabled={!dirty}>
            Save
          </Button>
        </>
      }
    >
      <PageContainer>
        <StyledCRow marginBottom="1em">
          <BoundedDateRangeDropdown
            value={{ range, start, end }}
            options={options}
            onApply={setDateRange}
          />

          <SelectSegmentDropDown
            value={segmentId}
            onChange={setSegmentId}
            isClearable
          />
          <TimestampRefresh endTime={updated} onClick={handleRefresh} />
        </StyledCRow>

        <SegmentWarningNotice
          segmentId={segmentId}
          selected
          start={startTime}
        />

        <CRow>
          <DeprecatedChart
            startTime={startTime}
            endTime={endTime}
            segmentId={segmentId}
            selectedEvents={chart.events}
            chartType={chartType}
            onSelectChartType={setChartType}
            shouldHideCheckbox
          />
        </CRow>
        <StyledCRow>
          <EventsByUserTable
            startTime={startTime}
            endTime={endTime}
            events={chart.events}
            segmentId={segmentId}
            pageSize={10}
          />
        </StyledCRow>
      </PageContainer>
    </IndexPage>
  );
};

ChartView.propTypes = {
  chart: PropTypes.shape({
    created_at: PropTypes.string,
    created_by: PropTypes.string,
    events: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.string,
        event: PropTypes.string,
      })
    ),
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    segment_id: PropTypes.string,
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    mode: PropTypes.oneOf(['FIXED', 'TRAILING']),
    trailing_days: PropTypes.string,
    updated_at: PropTypes.string,
    updated_by: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  userName: PropTypes.string,
  onRefresh: PropTypes.func,
  onSave: PropTypes.func,
};

const SyncedChartView = ({
  chart,
  options,
  userName,
  onLoad,
  onRefresh,
  onSave,
  synced,
}) => {
  useTitle('Dashboard | Appcues');
  useEffect(() => {
    onLoad();
  }, []);

  if (!synced || !chart) {
    return <Loader />;
  }

  return (
    <ChartView
      chart={chart}
      options={options}
      userName={userName}
      onRefresh={onRefresh}
      onSave={onSave}
    />
  );
};

SyncedChartView.propTypes = {
  ...ChartView.propTypes,
  onLoad: PropTypes.func,
  synced: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { chartId },
    },
  } = props;

  const chart = selectChart(state, chartId);

  let userName = null;
  if (chart) {
    const user = selectAccountUser(state, chart.created_by);
    userName = user && user.meta && user.meta.fullname;
  }

  return {
    chart,
    userName,
    options: selectDateRangeOptions(state),
    synced:
      selectAccountMetaSynced(state) && selectAccountFeaturesSynced(state),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const {
    match: {
      params: { chartId },
    },
  } = props;

  return {
    onLoad: () => dispatch(getChart({ id: chartId })),
    onRefresh: () => dispatch(clear()),
    onSave: delta => dispatch(update(chartId, delta)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SyncedChartView);
