import { connect } from 'react-redux';
import { FrequencyPanel } from '@studio/settings-panels';
import { selectRule, update as updateRule } from 'next/entities/rules';
import { selectAccount, update as updateAccount } from 'next/entities/account';
import { selectUser } from 'next/entities/user';
import {
  ENTITLEMENTS,
  selectEntitlementsByName,
} from 'next/entities/entitlements';

const mapStateToProps = (state, { id: flowId }) => {
  const { throttleEnabled, throttleContentLimit, throttleContentTimespanMs } =
    selectAccount(state);
  const rule = selectRule(state, flowId);
  const entitlement = selectEntitlementsByName(
    state,
    ENTITLEMENTS.FREQUENCY_ACCESS
  );

  const user = selectUser(state);
  const { email, fullname } = user;

  return {
    throttleEnabled,
    throttleContentLimit,
    throttleContentTimespanMs,
    throttleExempt: rule.throttleExempt,
    hasFrequencyAccess: entitlement.allowed_units > 0,
    email,
    fullname,
  };
};

const mapDispatchToProps = (dispatch, { id }) => ({
  onRuleChange: delta => dispatch(updateRule(id, delta)),
  onUpdateAccount: delta => dispatch(updateAccount(delta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FrequencyPanel);
