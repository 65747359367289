import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  CPage,
  CButton,
  Grid,
  Flex,
  P,
  Caption,
} from '@appcues/component-library';
import { ContentStatus } from '@studio/legacy-components';
import useTitle from 'next/hooks/use-title';
import { ENTITLEMENTS } from 'next/entities/entitlements';
import { HelpBar } from 'next/components/HelpBar';
import ChecklistUpgradeState from 'components/ChecklistsIndex/ChecklistUpgradeState';
import { selectEntitlementsByName } from 'selectors/entitlements-v2';
import { getEntitlements } from 'actions/entitlements-v2';
import Card from 'components/Common/Card';
import IndexPage from 'components/Common/IndexPage';
import Loader from 'components/Common/Loader';
import ChecklistEmptyState from 'components/ChecklistsIndex/ChecklistEmptyState/';
import {
  selectAccountChecklists,
  selectAccountChecklistsSynced,
  deleteChecklist,
  cloneChecklist,
  create,
} from 'entities/checklists';
import { navigate } from 'actions/routing';
import { showModal } from 'actions/currentModal';
import { DELETE_CHECKLIST_MODAL } from 'constants/globals';
import { checklistsText } from 'constants/checklistsText';

const StyledPageCaption = styled(P)`
  color: ${props => props.theme['$gray-5']};
  padding-top: 10px;
  padding-bottom: 10px;
`;

export function ChecklistsIndex({
  checklists,
  className,
  loaded,
  hasChecklistAccess,
  onCreate,
  onClone,
  onShowModal,
  onDelete,
  onNavigate,
  onLoad,
}) {
  const [loading, setLoading] = useState(false);
  useTitle('Checklists | Appcues');

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  if (!loaded) {
    return <Loader />;
  }

  const handleCreateList = () => {
    if (!loading) {
      setLoading(true);
      onCreate();
    }
  };

  const hasNoChecklists = loaded && Object.keys(checklists).length === 0;

  if (hasNoChecklists && hasChecklistAccess) {
    return (
      <ChecklistEmptyState
        isLoading={loading}
        createChecklist={handleCreateList}
      />
    );
  }

  const checklistCards = Object.keys(checklists).map(checklistId => {
    const checklist = checklists[checklistId];
    const dropdownOptions = [
      { label: 'Edit', value: 'edit', icon: 'edit' },
      { label: 'Clone', value: 'clone', icon: 'copy' },
    ];

    if (!checklist.published) {
      dropdownOptions.push({
        label: 'Delete',
        value: 'delete',
        icon: 'trash',
      });
    }

    const linkString = `/checklists/${checklistId}/analytics`;

    const onSelectDropdownValue = optionValue => {
      switch (optionValue) {
        case 'clone':
          onClone(checklistId);
          break;
        case 'delete':
          onShowModal(DELETE_CHECKLIST_MODAL, {
            checklistId,
            deleteChecklist: onDelete,
          });
          break;
        case 'edit':
          onNavigate(`/checklists/${checklistId}/edit`);
          break;
        default:
          break;
      }
    };

    return (
      <div role="gridcell" key={checklistId}>
        <Card
          title={checklist ? checklist.internalName : ''}
          dropdownOptions={dropdownOptions}
          header={checklist ? checklist.internalName : ''}
          onClick={() => onNavigate(linkString)}
          onSelectDropdownOption={onSelectDropdownValue}
        >
          <Flex alignItems="center" justifyContent="space-between">
            {checklist.createdAt && (
              <Caption type="tertiary">
                Created on {moment(checklist.createdAt).format('LL')}
              </Caption>
            )}
            <ContentStatus published={checklist.published} marginLeft="auto" />
          </Flex>
        </Card>
      </div>
    );
  });
  return (
    <IndexPage
      className={className}
      subHeaderActions={
        hasChecklistAccess && (
          <CButton
            isDisabled={loading}
            type="positive"
            onClick={handleCreateList}
          >
            Create Checklist
          </CButton>
        )
      }
      title="Checklists"
    >
      <CPage.Container>
        {hasChecklistAccess && (
          <>
            <HelpBar section="checklists" />
            <StyledPageCaption>{checklistsText.description}</StyledPageCaption>
            <Grid>{checklistCards}</Grid>
          </>
        )}

        {!hasChecklistAccess && <ChecklistUpgradeState />}
      </CPage.Container>
    </IndexPage>
  );
}

function mapStateToProps(state) {
  const checklistAccessEntitlement = selectEntitlementsByName(
    state,
    ENTITLEMENTS.CHECKLISTS_ACCESS
  );

  return {
    loaded: selectAccountChecklistsSynced(state) && checklistAccessEntitlement,
    checklists: selectAccountChecklists(state),
    hasChecklistAccess: checklistAccessEntitlement?.allowed_units > 0,
  };
}

const mapDispatchToProps = {
  onClone: cloneChecklist,
  onCreate: create,
  onDelete: deleteChecklist,
  onNavigate: navigate,
  onShowModal: showModal,
  onLoad: () => getEntitlements(),
};

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistsIndex);
