import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  SubTitle,
  Text,
  Button,
  Icon,
  Row,
  Input,
  Label,
  ButtonGroup,
  InlineMessage,
  toast,
  Badge,
  Alert,
} from '@appcues/sonar';
import { Select } from '@studio/legacy-components';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { string } from 'yup';
import { history } from 'next/lib/history';
import {
  inviteTeamMember,
  selectCanInviteTeamMembers,
  update,
} from 'next/entities/account-users';
import { selectUser } from 'next/entities/user';
import {
  selectEntitlementsByName,
  selectIsEntitledByName,
} from 'next/entities/entitlements';

import {
  ADMINISTRATOR_OPTION,
  EDITOR_OPTION,
  PUBLISHER_OPTION,
} from 'constants/accountManagement/permissions';

import {
  Wrapper,
  UserIntentCard,
  Header,
  Actions,
  UserIntentCardWrapper,
  Field,
  WrapperBeaconLink,
} from './styled';

const options = [
  {
    ...ADMINISTRATOR_OPTION,
    description: ADMINISTRATOR_OPTION.permissionDescription,
  },
  {
    ...PUBLISHER_OPTION,
    description: PUBLISHER_OPTION.permissionDescription,
  },
  {
    ...EDITOR_OPTION,
    description: EDITOR_OPTION.permissionDescription,
  },
];

export function InviteTeamMembers({
  user,
  canInvite,
  onInvite,
  trackingInvitation,
  entitlements,
  isEntitled,
}) {
  const [emailErrors, setEmailErrors] = useState({});
  const [sendingInvitations, setSendingInvitations] = useState(false);
  const [invitationFields, setInvitationFields] = useState([
    { id: 0, email: '', permission: options[0] },
  ]);

  const usedUnits = entitlements?.used_units;
  const allowedUnits = entitlements?.allowed_units;
  const remainingInvites = allowedUnits - usedUnits;
  const pendingInvites = invitationFields.length;
  const availableInvites = Math.max(0, remainingInvites - pendingInvites);

  const addInvitationField = () => {
    if (invitationFields.length >= 10) return;

    setInvitationFields(inputs => [
      ...inputs,
      { id: crypto.randomUUID(), email: '', permission: options[0] },
    ]);
  };

  const doValidation = index => {
    try {
      string().email().validateSync(invitationFields[index]?.email);
      setEmailErrors(s => ({ ...s, [index]: false }));
    } catch {
      setEmailErrors(s => ({ ...s, [index]: true }));
    }
  };

  const handleChange = (index, key, value) => {
    setInvitationFields(inputs => {
      const newInputs = [...inputs];
      newInputs[index][key] = value;
      return newInputs;
    });
  };

  const handleInvite = async () => {
    const hasError = Object.values(emailErrors).some(Boolean);

    if (hasError) return;

    setSendingInvitations(true);

    const members = invitationFields.filter(item => item.email);

    try {
      if (members.length > 0) {
        await Promise.all(
          members.map(async ({ email, permission }) =>
            onInvite({ email, role: permission.value })
          )
        );
        toast.success('Invitations sent successfully.');
      }

      trackingInvitation(user.id, { invitations: members.length });
      history.push('/');
    } catch (error) {
      toast.danger(error?.message ?? 'Something went wrong. Please try again.');
    }

    setSendingInvitations(false);
  };

  if (allowedUnits === undefined) {
    return null;
  }

  return (
    <Wrapper>
      <UserIntentCardWrapper>
        <UserIntentCard>
          <Header>
            <Row spacing="large" xAlign="start" yAlign="center">
              <SubTitle>I want to invite:</SubTitle>
              <Badge variant="default">
                <Icon colorToken="foreground-secondary" icon={faUser} />
                <Text weight="bold">{availableInvites} remaining</Text>
              </Badge>
            </Row>
            <Text>
              We suggest inviting teammates from Product, Marketing, UX/Design,
              Engineering, and Customer Success.
            </Text>
          </Header>

          <Wrapper>
            {invitationFields.map((item, index) => (
              <Row key={item.id} spacing="large" xAlign="start" yAlign="center">
                <Field>
                  <Label>Email</Label>
                  <Input
                    disabled={!canInvite || !isEntitled}
                    aria-label={`Email ${index + 1}`}
                    onBlur={() => doValidation(index)}
                    onChange={({ target: { value } }) => {
                      handleChange(index, 'email', value);
                    }}
                  />
                  {emailErrors[index] ? (
                    <InlineMessage variant="error">
                      Please enter a valid email address.
                    </InlineMessage>
                  ) : null}
                </Field>
                <Field>
                  <Label>Permissions</Label>
                  <Select
                    options={options}
                    isDisabled={!canInvite || !isEntitled}
                    isSearchable={false}
                    menuPlacement="auto"
                    value={item.permission}
                    onChange={permission =>
                      handleChange(index, 'permission', permission)
                    }
                    portal
                  />
                </Field>
              </Row>
            ))}

            {availableInvites > 0 ? (
              <Row wrap fluid>
                <Button
                  variant="tertiary"
                  disabled={!canInvite || availableInvites <= 0}
                  onClick={addInvitationField}
                >
                  <Icon icon={faPlus} />
                  Add another
                </Button>
              </Row>
            ) : (
              <Alert.Root variant="info" size="large">
                <Alert.Message>
                  <Alert.Title>
                    Looks like you're using up all your user seats.
                  </Alert.Title>
                  <Alert.Description>
                    You've reached your limit of{' '}
                    <Text forwardedAs="span" weight="bold">
                      {allowedUnits}
                    </Text>{' '}
                    team members.{' '}
                    <WrapperBeaconLink>Contact support</WrapperBeaconLink> to
                    increase your team size.
                  </Alert.Description>
                </Alert.Message>
              </Alert.Root>
            )}
          </Wrapper>

          <Actions>
            <ButtonGroup>
              <Button
                variant="tertiary"
                onClick={() => {
                  trackingInvitation(user.id, { invitations: 0 });
                  history.push('/');
                }}
              >
                Skip for now
              </Button>
              <Button loading={sendingInvitations} onClick={handleInvite}>
                Invite and start building
                <Icon icon="arrow-right" />
              </Button>
            </ButtonGroup>
            <Button
              variant="secondary"
              onClick={() => history.push('/welcome')}
            >
              <Icon icon="arrow-left" />
              Back
            </Button>
          </Actions>
        </UserIntentCard>
      </UserIntentCardWrapper>
    </Wrapper>
  );
}

InviteTeamMembers.propTypes = {
  user: PropTypes.shape({ id: PropTypes.string }),
  onInvite: PropTypes.func,
  trackingInvitation: PropTypes.func,
  canInvite: PropTypes.bool,
  entitlements: PropTypes.shape({
    used_units: PropTypes.number,
    allowed_units: PropTypes.number,
  }),
  isEntitled: PropTypes.bool,
};

const mapDispatchToProps = {
  onInvite: inviteTeamMember,
  trackingInvitation: update,
};

const mapStateToProps = state => ({
  user: selectUser(state),
  canInvite: selectCanInviteTeamMembers(state),
  entitlements: selectEntitlementsByName(state, 'INVITE_USER'),
  isEntitled: selectIsEntitledByName(state, 'INVITE_USER'),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteTeamMembers);
