import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import * as yup from 'yup';
import isEmpty from 'lodash.isempty';
import {
  Button,
  ButtonGroup,
  Description,
  ErrorMsg,
  ExternalLink,
  Heading,
  Input,
  Label,
  Modal,
  Overflow,
  getEndOfToday,
} from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import { BoundedDateRangePicker } from 'next/components/BoundedDateRange';
import {
  FULL_NPS_RANGE_OPTIONS,
  LIMITED_NPS_RANGE_OPTIONS,
} from 'components/nps/constants';
import { requestNpsExport } from 'entities/nps';
import { selectUserEmail } from 'reducers/user';
import { EXTRACTION_V2 } from 'constants/features';
import { selectAccountFeature } from 'reducers/account/features';
import { selectDateRangeType } from 'selectors/timeframe-options';
import { ExportForm } from './styled';

/**
 * URL to NPS events and properties help article
 */
const HELP_ARTICLE =
  'https://docs.appcues.com/article/349-nps-events-and-properties';

export function ExportManager({
  limited,
  errors = {},
  onSubmit,
  setValues,
  useV2Extraction = false,
  values = {},
}) {
  const [visible, toggle] = useToggle();

  const handleExport = event => {
    event.preventDefault();
    const { email, end, start } = values;
    onSubmit({
      email,
      endDate: end?.getTime(),
      startDate: start?.getTime(),
      useV2: useV2Extraction,
    });
    toggle();
  };

  const handleDateChange = ({ range, start, end }) => {
    // NOTE: `null` is used so that when start/end are `undefined`, the object
    //       spread will correctly set the falsy values rather than being
    //       omitted since `undefined` values are skipped over during the merge.
    setValues({ ...values, range, start: start ?? null, end: end ?? null });
  };

  return (
    <>
      <Button kind="secondary" onClick={toggle}>
        Export
      </Button>

      <Modal closeOnEscape={false} onClose={toggle} size="m" visible={visible}>
        <ExportForm onSubmit={handleExport}>
          <Heading>Export a CSV</Heading>

          <Description>
            The CSV file will include your scores and qualitative feedback. To
            understand the event names in your file{' '}
            <ExternalLink href={HELP_ARTICLE}>learn more here</ExternalLink>.
          </Description>

          <Overflow>
            <BoundedDateRangePicker
              onChange={handleDateChange}
              options={
                limited ? LIMITED_NPS_RANGE_OPTIONS : FULL_NPS_RANGE_OPTIONS
              }
              portal
              stacked
              value={values}
            />

            <Label>
              Email address – we’ll send your CSV here
              <Input
                error={!!errors.email}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, email: value })
                }
                type="email"
                value={values.email}
              />
              {errors.email && <ErrorMsg>{errors.email}</ErrorMsg>}
            </Label>
          </Overflow>

          <ButtonGroup right>
            <Button disabled={!isEmpty(errors)} kind="primary" type="submit">
              Send CSV
            </Button>
            <Button kind="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ButtonGroup>
        </ExportForm>
      </Modal>
    </>
  );
}

ExportManager.propTypes = {
  limited: PropTypes.bool,
  onSubmit: PropTypes.func,

  // Props injected by Formik HOC
  errors: PropTypes.shape({
    email: PropTypes.string,
  }),
  setValues: PropTypes.func,
  useV2Extraction: PropTypes.bool,
  values: PropTypes.shape({
    email: PropTypes.string,
  }),
};

const mapStateToProps = state => {
  const type = selectDateRangeType(state);

  return {
    email: selectUserEmail(state),
    limited: type === null,
    useV2Extraction: selectAccountFeature(state, EXTRACTION_V2),
  };
};

const mapDispatchToProps = {
  onSubmit: requestNpsExport,
};

const enhanceForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ email = '', range, end, start }) => ({
    email,
    range,
    end,
    start,
  }),
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email('Please enter a valid email address.')
      .required('Email is required to proceed.'),
    end: yup.date().max(getEndOfToday()),
    start: yup.date().max(getEndOfToday()),
  }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(enhanceForm(ExportManager));
