import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';

import { Caption, H1, Column, CSkeleton } from '@appcues/component-library';
import Card from 'components/Common/Card';
import PercentOfUsersDisplay from 'components/Common/PercentOfUsersDisplay';
import { navigate } from 'actions/routing';
import { numberWithCommas } from 'utils/textFormatting';
import { GOAL_SINGLE_METRIC_URL } from 'components/Insights/Explorer/Sources/GoalRow';

export function GoalCard({
  className,
  goal,
  goalsFirstReachedThisWeek,
  navigate: navigateTo,
  onActivate,
  onDeactivate,
  onDelete,
  hasGoalsExplorer,
  isGoalsEntitled,
}) {
  const handleClick = () => {
    navigateTo(`goals/${goal.id}`);
  };

  const handleSelectDropdownOption = optionValue => {
    switch (optionValue) {
      case 'activate':
        if (onActivate) {
          onActivate(goal);
        }
        break;

      case 'deactivate':
        if (onDeactivate) {
          onDeactivate(goal);
        }
        break;

      case 'delete':
        if (onDelete) {
          onDelete(goal.id);
        }
        break;

      case 'analytics':
        navigateTo(`${GOAL_SINGLE_METRIC_URL}?id=${goal.id}`);
        break;

      default:
        break;
    }
  };

  const dropdownOptions = [];

  if (hasGoalsExplorer) {
    dropdownOptions.push({
      label: 'View analytics',
      value: 'analytics',
      icon: 'chart-bar',
    });
  }

  if (onActivate) {
    dropdownOptions.push({
      label: 'Activate',
      value: 'activate',
      icon: 'plus',
      disabled: !isGoalsEntitled,
    });
  }

  if (onDeactivate) {
    dropdownOptions.push({
      label: 'Deactivate',
      value: 'deactivate',
      icon: 'minus',
    });
  }

  if (onDelete) {
    dropdownOptions.push({
      label: 'Delete',
      value: 'delete',
      icon: 'trash',
    });
  }

  const goalsFirstReachedThisWeekSynced =
    goalsFirstReachedThisWeek && goalsFirstReachedThisWeek.meta.synced;

  let numGoalFirstReachedThisWeek = 0;
  let numActiveUsers = 0;
  let pctFirstAchievers = 0;

  if (goalsFirstReachedThisWeekSynced) {
    const goalReachedStats = (goalsFirstReachedThisWeek.data || []).find(
      goalStats => goalStats.goal_id === goal.id
    );

    if (goalReachedStats) {
      numGoalFirstReachedThisWeek = goalReachedStats.num_first_achievers;
      numActiveUsers = goalReachedStats.num_active_users;
      pctFirstAchievers = goalReachedStats.pct_first_achievers;
    }
  }

  const goalFirstReachedThisWeek = goalsFirstReachedThisWeekSynced ? (
    numberWithCommas(numGoalFirstReachedThisWeek)
  ) : (
    <CSkeleton width="1.5em" height="1.25em" marginBottom="0.25em" />
  );

  return (
    <Card
      className={classNames(className, 'goal-card')}
      header={goal.name || goal.id}
      dropdownOptions={dropdownOptions}
      onClick={handleClick}
      onSelectDropdownOption={handleSelectDropdownOption}
    >
      {!goal.disabled && (
        <Column marginTop={8} marginBottom={16}>
          <H1>{goalFirstReachedThisWeek}</H1>
          <Caption color="$gray-5">
            <PercentOfUsersDisplay
              userPercentage={pctFirstAchievers}
              totalUsers={numActiveUsers}
              loaded={!!goalsFirstReachedThisWeekSynced}
            />
          </Caption>
        </Column>
      )}
      {goal.disabledLastUpdated && (
        <Caption type="tertiary">
          {goal.disabled ? 'Last Active on' : 'Active since'}{' '}
          {moment(goal.disabledLastUpdated).format('LL')}
        </Caption>
      )}
    </Card>
  );
}

export default connect(null, { navigate })(GoalCard);
