import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import useToggle from 'next/hooks/use-toggle';
import { openExperience } from 'next/lib/builder';
import { OpenInBuilderManager } from 'next/components/OpenInBuilder';
import { selectPin } from 'next/entities/pins';
import { selectUser } from 'next/entities/users';

import Header, { HeaderPropTypes } from 'next/components/Header';
import useTitle from 'next/hooks/use-title';
import { selectSchedule } from 'next/entities/schedules';
import { selectExperienceHistory } from 'next/entities/experience-versions';

const PinHeader = ({
  id,
  name,
  state,
  previewUrl,
  published,
  createdAt,
  createdByName,
  updatedAt,
  updatedByName,
  renderActions,
  currentPage = 'settings',
  schedule,
  history,
}) => {
  const isPublishedAndSettings = currentPage === 'settings' && published;

  useTitle(
    `${name} | ${
      currentPage === 'settings' ? 'Settings' : 'Analytics'
    } | Appcues`
  );
  const [builderModalVisible, toggleBuilderModal] = useToggle();

  const handleOpenInBuilder = useCallback(() => {
    openExperience({ experience: id, url: previewUrl });
  }, [id, previewUrl]);

  return (
    <>
      <Header
        id={id}
        name={name}
        state={state}
        published={published}
        schedule={schedule}
        returnUrl={isPublishedAndSettings ? `/pins/${id}/analytics` : `/pins`}
        title={isPublishedAndSettings ? 'Analytics' : 'Pins'}
        createdAt={createdAt}
        createdByName={createdByName}
        updatedAt={updatedAt}
        updatedByName={updatedByName}
        history={history}
        renderActions={() =>
          renderActions?.({
            id,
            updatedAt,
            published,
            onClickOpenInBuilder: toggleBuilderModal,
          })
        }
      />
      <OpenInBuilderManager
        onClose={toggleBuilderModal}
        onNavigate={handleOpenInBuilder}
        type="pins"
        visible={builderModalVisible}
      />
    </>
  );
};

PinHeader.propTypes = {
  ...HeaderPropTypes,
  id: PropTypes.string,
  previewUrl: PropTypes.string,
};

const mapStateToProps = (state, { id }) => {
  const {
    published,
    state: pinState,
    updatedAt,
    updatedBy,
    createdAt,
    createdBy,
    name,
    previewUrl,
  } = selectPin(state, id) || {};
  const createdByUser = selectUser(state, createdBy);
  const updatedByUser = selectUser(state, updatedBy);

  return {
    id,
    name,
    state: pinState,
    published,
    createdAt,
    createdByName: createdByUser?.fullname,
    updatedAt,
    updatedByName: updatedByUser?.fullname,
    previewUrl,
    schedule: selectSchedule(state),
    history: selectExperienceHistory(state, id),
  };
};

export default connect(mapStateToProps)(PinHeader);
