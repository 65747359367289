import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  MenuList,
  SquareButton,
  Button,
} from '@studio/legacy-components';
import PublishingActions from 'next/components/PublishingActions';
import ExperiencePublishingManager from 'next/components/ExperiencePublishingManager';
import ExperienceContext from 'next/components/ExperienceContext';
import SchedulingModalProvider from 'next/components/ExperienceScheduling/SchedulingModalProvider';
import { history } from 'next/lib/history';
import { publishedEntitlementShape } from 'next/entities/entitlements';

const onClickRedirectTo = path => {
  history.push(path);
};
export default function HeaderActions({
  id,
  published,
  updatedAt,
  onClickOpenInBuilder,
  hideScheduling = false,
  hideDropdown = false,
  hideOpenInBuilderBtn = false,
  isEntitledToPublish,
  publishedEntitlement,
}) {
  const { type, routePath } = useContext(ExperienceContext);

  return (
    <>
      <ExperiencePublishingManager id={id}>
        {({
          disabled,
          hasChanges,
          onConfirmPublish,
          onConfirmDiscardChanges,
          onConfirmUnpublish,
        }) => (
          <PublishingActions
            published={published}
            updatedAt={updatedAt}
            disabled={disabled}
            hasChanges={hasChanges}
            onConfirmPublish={onConfirmPublish}
            onConfirmDiscardChanges={onConfirmDiscardChanges}
            onConfirmUnpublish={onConfirmUnpublish}
            type={type}
            id={id}
            isEntitledToPublish={isEntitledToPublish}
            publishedEntitlement={publishedEntitlement}
          />
        )}
      </ExperiencePublishingManager>

      {!hideScheduling && (
        <SchedulingModalProvider
          contentId={id}
          experienceType={type}
          published={published}
        />
      )}

      {!hideOpenInBuilderBtn && (
        <Button onClick={() => onClickRedirectTo(`${routePath}/${id}/builder`)}>
          Open in Builder
        </Button>
      )}

      {!hideDropdown && (
        <Dropdown
          aria-label={`Open ${type} actions`}
          attachment={
            <MenuList
              options={[
                { label: 'Open in Builder', onClick: onClickOpenInBuilder },
                {
                  label: 'Analytics',
                  onClick: () =>
                    onClickRedirectTo(`${routePath}/${id}/analytics`),
                },
              ]}
            />
          }
        >
          <SquareButton kind="secondary" icon="ellipsis-h" />
        </Dropdown>
      )}
    </>
  );
}

HeaderActions.propTypes = {
  id: PropTypes.string,
  published: PropTypes.bool,
  updatedAt: PropTypes.number,
  onClickOpenInBuilder: PropTypes.func,
  hideScheduling: PropTypes.bool,
  hideDropdown: PropTypes.bool,
  hideOpenInBuilderBtn: PropTypes.bool,
  isEntitledToPublish: PropTypes.bool,
  publishedEntitlement: publishedEntitlementShape,
};
