import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  CPage,
  CButton,
  Grid,
  Box,
  Flex,
  P,
  H2,
  Caption,
} from '@appcues/component-library';
import { Tooltip } from '@appcues/sonar';
import { IconWithinCButton, Link } from '@studio/legacy-components';
import { ENTITLEMENTS } from 'next/entities/entitlements';
import { DocumentTitle } from 'next/hooks/use-title';
import { GOALS_EVENTS_EXPLORER } from 'next/entities/features';
import EmptyState from 'components/Common/EmptyState';
import IndexPage from 'components/Common/IndexPage';
import Loader from 'components/Common/Loader';
import GoalCard from 'components/GoalsIndex/GoalCard';
import CoolTip from 'components/Common/CoolTip';

import { showModal } from 'actions/currentModal';
import { selectUserRole } from 'reducers/account/users';
import { selectUserId } from 'reducers/user';
import { update, deleteGoal } from 'actions/account/goals';
import { navigate } from 'actions/routing';
import {
  selectAccountGoals,
  selectAccountGoalsSynced,
  selectGoalsWithFlowConnected,
} from 'reducers/account/goals';

import { GOALS_DOC_URL } from 'constants/externalAppcuesResources';
import { PLAN_TO_TIER_MAPPINGS, PLAN_TYPES } from 'constants/plans';
import { DELETE_GOAL_MODAL, FLOW_DEPENDENCY_MODAL } from 'constants/globals';
import { ACCOUNT_PERMISSIONS } from 'constants/accountManagement/permissions';

import * as persistence from 'helpers/persistence';
import { computeGoalsLimit } from 'helpers/goals';
import {
  selectAccountMeta,
  selectAccountMetaSynced,
} from 'reducers/account/meta';
import { selectGoalsFirstReachedThisWeek } from 'reducers/analytics';
import { fetchGoalsFirstReachedThisWeek } from 'actions/analytics';
import { selectAccountFeature } from 'reducers/account/features';
import { SELECTED_EVENT_SOURCE } from 'components/Insights/constants';
import {
  selectEntitlementsByName,
  selectIsEntitledByName,
} from 'selectors/entitlements-v2';
import { BeaconLink } from 'components/Common/HelpScout';
import { getEntitlements } from 'actions/entitlements-v2';

const HighlightedText = styled.span`
  font-weight: bold;
`;

function sortByLastActiveDate(ascending) {
  const order = ascending ? -1 : 1;
  return (goalA, goalB) => {
    try {
      if (
        goalA.enabledTs[goalA.enabledTs.length - 1] <=
        goalB.enabledTs[goalB.enabledTs.length - 1]
      ) {
        return order;
      }
      return -1 * order;
    } catch {
      return 0;
    }
  };
}

function filterToActiveGoals(goals) {
  return Object.keys(goals || {})
    .map(goalId => goals[goalId])
    .filter(goal => !goal.disabled)
    .sort(sortByLastActiveDate(true));
}

function filterToInactiveGoals(goals) {
  return Object.keys(goals || {})
    .map(goalId => goals[goalId])
    .filter(goal => goal.disabled)
    .sort(sortByLastActiveDate(true));
}

export class GoalsIndex extends Component {
  constructor(props) {
    super(props);
    const { loaded, accountMetadata, allGoals, fetchEntitlements } = props;
    if (loaded) {
      fetchEntitlements();
      const maxNumberOfActiveGoals = computeGoalsLimit(accountMetadata);
      const numberOfActiveGoals = filterToActiveGoals(allGoals).length;
      this.state = {
        maxNumberOfActiveGoals,
        numberOfActiveGoals,
      };
    } else {
      this.state = {};
    }
  }

  componentDidMount() {
    const { onLoad } = this.props;
    onLoad();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { allGoals, loaded, accountMetadata } = nextProps;

    if (loaded) {
      const { numberOfActiveGoals, maxNumberOfActiveGoals, planTitle } =
        this.state;
      const nextMaxNumberOfActiveGoals = computeGoalsLimit(accountMetadata);
      const nextNumberOfActiveGoals = filterToActiveGoals(allGoals).length;

      if (!maxNumberOfActiveGoals) {
        this.setState({ maxNumberOfActiveGoals: nextMaxNumberOfActiveGoals });
      }

      if (numberOfActiveGoals !== nextNumberOfActiveGoals) {
        this.setState({
          numberOfActiveGoals: nextNumberOfActiveGoals,
        });
      }

      if (!planTitle) {
        const tier = PLAN_TO_TIER_MAPPINGS[accountMetadata.stripePlanId];
        const plan = PLAN_TYPES.reduce((memo, planType) => {
          if (planType.id === tier) {
            return planType;
          }
          return memo;
        }, null);
        if (
          (accountMetadata.planLimitOverrides &&
            typeof accountMetadata.planLimitOverrides.maxGoals === 'number') ||
          !plan
        ) {
          this.setState({ planTitle: 'Custom Plan' });
        } else if (tier === 'trial') {
          this.setState({ planTitle: 'Trial' });
        } else {
          this.setState({ planTitle: `${plan.name} Plan` });
        }
      }
    }
  }

  GoalButton = ({ cannotCreateGoal, onClickCreate, isGoalsEntitled }) => {
    const baseGoalButton = (
      <CButton
        isDisabled={cannotCreateGoal || !isGoalsEntitled}
        key="goals-create"
        type="positive"
        onClick={onClickCreate}
      >
        Create a goal
      </CButton>
    );

    if (cannotCreateGoal) {
      return (
        <CoolTip
          tip="Contact a team admin or publisher to create goals."
          position="bottom"
          marginRight={18}
        >
          {baseGoalButton}
        </CoolTip>
      );
    }
    return baseGoalButton;
  };

  onClickCreate = () => {
    const { onNavigate } = this.props;

    onNavigate('/goals/new');
  };

  onClickActivate = async goal => {
    const { onChangeGoal, fetchEntitlements } = this.props;

    try {
      await onChangeGoal(goal.id, { disabled: false });
    } finally {
      setTimeout(() => {
        fetchEntitlements();
      }, 500);
    }
  };

  onClickDeactivate = async goal => {
    const { onShowModal, onChangeGoal, connectedFlows, fetchEntitlements } =
      this.props;
    const hasFlowConnected = Boolean(connectedFlows[goal.id]);

    if (hasFlowConnected) {
      onShowModal(FLOW_DEPENDENCY_MODAL, {
        title: 'Unable to Deactivate Goal',
        description:
          'To deactivate this goal, remove it from the settings in the following areas:',
        flows: connectedFlows[goal.id],
      });
    } else {
      try {
        await onChangeGoal(goal.id, { disabled: true });
      } finally {
        setTimeout(() => {
          fetchEntitlements();
        }, 500);
      }
    }
  };

  onClickDelete = async goalId => {
    const {
      onShowModal,
      onDeleteGoal,
      allGoals,
      connectedFlows,
      fetchEntitlements,
    } = this.props;
    const hasFlowConnected = Boolean(connectedFlows[goalId]);
    const goalName = allGoals[goalId].name;

    if (hasFlowConnected) {
      onShowModal(FLOW_DEPENDENCY_MODAL, {
        title: 'Unable to Delete Goal',
        description:
          'To delete this goal, remove it from the settings in the following areas:',
        flows: connectedFlows[goalId],
      });
    } else {
      onShowModal(DELETE_GOAL_MODAL, {
        goalId,
        goalName,
        deleteGoal: async (...props) => {
          await onDeleteGoal(...props);
          setTimeout(() => {
            fetchEntitlements();
          }, 1000);
        },
      });
    }
  };

  render() {
    const {
      className,
      loaded,
      allGoals,
      goalsFirstReachedThisWeek,
      role,
      hasGoalsExplorer,
      isGoalsEntitled,
      goalsEntitlement,
    } = this.props;

    const goalsExist = allGoals && Object.keys(allGoals).length > 0;
    const cannotCreateGoal = role === ACCOUNT_PERMISSIONS.EDITOR;

    if (!loaded) {
      return <Loader />;
    }

    return (
      <IndexPage
        className={className}
        title="Goals"
        subHeaderActions={[
          <CButton key="help" is="a" isExternal href={GOALS_DOC_URL}>
            <IconWithinCButton icon="question-circle" gray />
            Help
          </CButton>,
          <Tooltip
            disabled={isGoalsEntitled}
            position="bottom"
            size="regular"
            content={
              <>
                You've reached your plan limit of{' '}
                <HighlightedText>
                  {goalsEntitlement?.allowed_units}
                </HighlightedText>{' '}
                goal{goalsEntitlement?.allowed_units > 1 ? 's' : ''}.{' '}
                <BeaconLink>Contact support</BeaconLink> to increase the limit
                or you can deactivate a goal.
              </>
            }
          >
            <this.GoalButton
              key="goal"
              cannotCreateGoal={cannotCreateGoal}
              onClickCreate={this.onClickCreate}
              isGoalsEntitled={isGoalsEntitled}
            />
          </Tooltip>,
        ]}
      >
        <DocumentTitle title="Goals | Appcues" />
        <CPage.Container>
          {goalsExist && (
            <>
              <Box>
                <H2 type="secondary">Active Goals</H2>
                {hasGoalsExplorer ? (
                  <P color="$gray-5" paddingY={10}>
                    These goals are actively being measured. Analyze your{' '}
                    <Link
                      href="/insights/explorer"
                      onClick={() => {
                        persistence.setItem(SELECTED_EVENT_SOURCE, 'goals');
                      }}
                    >
                      goal completion reporting
                    </Link>{' '}
                    for more insights.
                  </P>
                ) : (
                  <P color="$gray-5" paddingY={10}>
                    These goals are active and being measured. You can choose
                    any active goals when editing a flow to measure its effect.
                  </P>
                )}
                <Grid>
                  {filterToActiveGoals(allGoals).map(goal => (
                    <GoalCard
                      key={goal}
                      goal={goal}
                      goalsFirstReachedThisWeek={goalsFirstReachedThisWeek}
                      onDeactivate={this.onClickDeactivate}
                      onDelete={this.onClickDelete}
                      hasGoalsExplorer={hasGoalsExplorer}
                    />
                  ))}
                </Grid>
              </Box>
              <Box marginTop={48}>
                <H2 type="secondary">Inactive Goals</H2>
                <P color="$gray-5" paddingY={10}>
                  These goals aren&apos;t being measured. You can reactivate
                  them at any time.
                </P>
                <Grid>
                  {filterToInactiveGoals(allGoals).map(goal => (
                    <GoalCard
                      key={goal}
                      goal={goal}
                      onActivate={this.onClickActivate}
                      onDelete={this.onClickDelete}
                      hasGoalsExplorer={hasGoalsExplorer}
                      isGoalsEntitled={isGoalsEntitled}
                    />
                  ))}
                </Grid>
              </Box>
            </>
          )}

          {!goalsExist && (
            <EmptyState
              customVisualNode={<GoalBarGraphs />}
              header="Use goals to measure the impact of your flows."
              body="Directly measure how your content influences user behaviors - including activation moments, feature adoption, upgrades, and more."
            >
              <CButton
                type="positive"
                marginTop={48}
                onClick={this.onClickCreate}
              >
                Create your first goal!
              </CButton>
            </EmptyState>
          )}
        </CPage.Container>
      </IndexPage>
    );
  }
}

function mapStateToProps(state) {
  return {
    loaded: selectAccountGoalsSynced(state) && selectAccountMetaSynced(state),
    allGoals: selectAccountGoals(state),
    accountMetadata: selectAccountMeta(state),
    goalsFirstReachedThisWeek: selectGoalsFirstReachedThisWeek(state),
    role: selectUserRole(state, selectUserId(state)),
    hasGoalsExplorer: selectAccountFeature(state, GOALS_EVENTS_EXPLORER),
    connectedFlows: selectGoalsWithFlowConnected(state),
    goalsEntitlement: selectEntitlementsByName(state, ENTITLEMENTS.GOALS),
    isGoalsEntitled: selectIsEntitledByName(state, ENTITLEMENTS.GOALS),
  };
}

const mapDispatchToProps = {
  onShowModal: showModal,
  onNavigate: navigate,
  onLoad: fetchGoalsFirstReachedThisWeek,
  onChangeGoal: update,
  onDeleteGoal: deleteGoal,
  fetchEntitlements: getEntitlements,
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalsIndex);

const BarBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0px 12px 34px rgba(71, 88, 114, 0.35);
  width: 154px;
  height: ${props => props.height};
  overflow: hidden;
`;

const ImageContainer = styled.div`
  width: 55%;
  display: flex;
  align-self: flex-end;
  justify-content: space-between;
  align-items: center;
  height: 158px;
`;

const GoalBarGraphs = () => (
  <ImageContainer>
    <BarBox height={158}>
      <Box backgroundImage="$grapefruit" height={94} />
      <Flex alignItems="baseline" justifyContent="center" paddingY={12}>
        <H2 type="secondary">50%</H2>
        <Caption type="tertiary">&nbsp; of users</Caption>
      </Flex>
    </BarBox>
    <BarBox height={112}>
      <Box backgroundImage="$merfolk" height={48} />
      <Flex alignItems="baseline" justifyContent="center" paddingY={12}>
        <H2 type="secondary">25%</H2>
        <Caption type="tertiary">&nbsp; of users</Caption>
      </Flex>
    </BarBox>
  </ImageContainer>
);
