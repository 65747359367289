import React from 'react';
import { Row, Stack, Text, Icon, Link } from '@appcues/sonar';
import { faHandWave } from '@fortawesome/pro-regular-svg-icons/faHandWave';
import { faChartLineUp } from '@fortawesome/pro-regular-svg-icons/faChartLineUp';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons/faSparkles';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons/faArrowUpRightFromSquare';
import { BeaconLink } from 'components/Common/HelpScout';
import {
  StyledConfigurationCard,
  StyledHeading,
  ChecklistImage,
  StyledButton,
} from './styled';

const ChecklistUpgradeState = () => {
  return (
    <StyledConfigurationCard yAlign="center">
      <Row spacing="regular" xAlign="center">
        <Stack spacing="regular" xAlign="center" yAlign="center">
          <figure>
            <ChecklistImage alt="Checklist image" />
          </figure>
          <StyledHeading size="large" weight="bold">
            Activate Users and Keep them Coming Back with Checklists
          </StyledHeading>
          <Stack spacing="small">
            <Text>
              <Icon icon={faHandWave} colorToken="color-blurple-600" /> Onboard
              users with ease
            </Text>
            <Text>
              <Icon icon={faChartLineUp} colorToken="color-blurple-600" />{' '}
              Capture attention and boost engagement
            </Text>
            <Text>
              <Icon icon={faSparkles} colorToken="color-blurple-600" /> Reduce
              time-to-value
            </Text>
          </Stack>

          <BeaconLink forwardedAs={StyledButton}>Upgrade</BeaconLink>

          <Text>
            Learn more about{' '}
            <Link
              inline
              href="https://docs.appcues.com/en_US/user-experiences-building/checklists-overview"
              icon={faArrowUpRightFromSquare}
            >
              checklists
            </Link>
            .
          </Text>
        </Stack>
      </Row>
    </StyledConfigurationCard>
  );
};

export default ChecklistUpgradeState;
