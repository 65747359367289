import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, MenuList, SquareButton } from '@studio/legacy-components';

import PublishingActions from 'next/components/PublishingActions';
import SchedulingModalProvider from 'next/components/ExperienceScheduling/SchedulingModalProvider';
import { history } from 'next/lib/history';
import { publishedEntitlementShape } from 'next/entities/entitlements';
import PinsPublishingManager from './PinsPublishingManager';

const onClickRedirectTo = path => {
  history.push(path);
};
export default function HeaderActions({
  id,
  published,
  updatedAt,
  onClickOpenInBuilder,
  isEntitledToPublish,
  publishedEntitlement,
}) {
  return (
    <>
      <PinsPublishingManager id={id}>
        {({
          disabled,
          hasChanges,
          onConfirmPublish,
          onConfirmDiscardChanges,
          onConfirmUnpublish,
        }) => (
          <PublishingActions
            published={published}
            updatedAt={updatedAt}
            disabled={disabled}
            hasChanges={hasChanges}
            onConfirmPublish={onConfirmPublish}
            onConfirmDiscardChanges={onConfirmDiscardChanges}
            onConfirmUnpublish={onConfirmUnpublish}
            isEntitledToPublish={isEntitledToPublish}
            publishedEntitlement={publishedEntitlement}
          />
        )}
      </PinsPublishingManager>

      <SchedulingModalProvider
        contentId={id}
        experienceType="Pin"
        published={published}
      />

      <Dropdown
        aria-label="Open pins actions"
        attachment={
          <MenuList
            options={[
              { label: 'Open in Builder', onClick: onClickOpenInBuilder },
              {
                label: 'Analytics',
                onClick: () => onClickRedirectTo(`/pins/${id}/analytics`),
              },
            ]}
          />
        }
      >
        <SquareButton kind="secondary" icon="ellipsis-h" />
      </Dropdown>
    </>
  );
}

HeaderActions.propTypes = {
  id: PropTypes.string,
  published: PropTypes.bool,
  updatedAt: PropTypes.number,
  onClickOpenInBuilder: PropTypes.func,
  isEntitledToPublish: PropTypes.bool,
  publishedEntitlement: publishedEntitlementShape,
};
