import styled from 'styled-components';
import { Button as SonarButton } from '@appcues/sonar';

export const VersionList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
`;

export const VersionListItem = styled.li`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 8px;
`;

export const Button = styled(SonarButton)`
  margin-left: 8px;
`;

export const User = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Date = styled.div``;
