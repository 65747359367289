import React from 'react';
import styled from 'styled-components';
import { faFaceSmile } from '@fortawesome/pro-regular-svg-icons/faFaceSmile';
import { ExternalLink } from '@studio/legacy-components';
import { faCircleArrowDownLeft } from '@fortawesome/pro-regular-svg-icons/faCircleArrowDownLeft';
import { faBurst } from '@fortawesome/pro-regular-svg-icons/faBurst';
import { Icon, Row, Section, Stack, Text, Button } from '@appcues/sonar';
import { BeaconLink } from 'next/components/BeaconLink';
import { Subtitle } from 'next/lib/devtools/styled';
import NoSeatsImage from './NoSeatsImage';

const StyledButton = styled(Button)`
  margin-top: 16px;
  display: inline-block;
  text-decoration: none;
  font-size: unset;
`;

export default function NoSeats() {
  return (
    <Section>
      <Stack xAlign="center">
        <NoSeatsImage />
        <Subtitle>Empower Users with a Centralized Resource Hub</Subtitle>
        <Stack spacing="small">
          <Row yAlign="center" spacing="small">
            <Icon icon={faBurst} colorToken="color-blurple-600" />
            <Text>Turn everyday users into power users</Text>
          </Row>
          <Row yAlign="center" spacing="small">
            <Icon icon={faFaceSmile} colorToken="color-blurple-600" />
            <Text>Prevent user frustration</Text>
          </Row>
          <Row yAlign="center" spacing="small">
            <Icon icon={faCircleArrowDownLeft} colorToken="color-blurple-600" />
            <Text>Reduce support tickets</Text>
          </Row>
        </Stack>
        <BeaconLink forwardedAs={StyledButton}>Upgrade</BeaconLink>
        <Row>
          <Text>
            To learn more about{' '}
            <ExternalLink href="https://docs.appcues.com/user-experiences-building/launchpads-20">
              Launchpads
            </ExternalLink>
          </Text>
        </Row>
      </Stack>
    </Section>
  );
}
