import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BaseHeader, { HeaderPropTypes } from 'next/components/Header';
import { selectUser } from 'next/entities/users';
import { selectExperience } from 'next/entities/experiences';
import useTitle from 'next/hooks/use-title';
import { selectSchedule } from 'next/entities/schedules';
import { selectExperienceHistory } from 'next/entities/experience-versions';
import { ScheduleType } from 'next/components/ExperienceScheduling/types';

export function MobileHeader({
  appId,
  id,
  name,
  state,
  published,
  createdAt,
  createdByName,
  updatedAt,
  updatedByName,
  renderActions,
  currentPage = 'settings',
  experiment,
  schedule,
  history,
}) {
  const isPublishedAndSettings = currentPage === 'settings' && published;

  useTitle(
    `${name} | ${
      currentPage === 'settings' ? 'Settings' : 'Analytics'
    } | Appcues`
  );
  return (
    <BaseHeader
      id={id}
      name={name}
      state={state}
      schedule={schedule}
      published={published}
      appId={appId}
      experiment={experiment}
      returnUrl={
        isPublishedAndSettings ? `/mobile/flows/${id}/analytics` : `/flows`
      }
      title={isPublishedAndSettings ? 'Analytics' : 'Flows'}
      createdAt={createdAt}
      createdByName={createdByName}
      updatedAt={updatedAt}
      updatedByName={updatedByName}
      history={history}
      renderActions={() =>
        renderActions?.({
          id,
          updatedAt,
          published,
          state,
        })
      }
    />
  );
}

MobileHeader.propTypes = {
  ...HeaderPropTypes,
  id: PropTypes.string,
  appId: PropTypes.string,
  schedule: ScheduleType,
};

const mapStateToProps = (state, { id }) => {
  const {
    appId,
    published,
    state: experienceState,
    updatedAt,
    updatedBy,
    createdAt,
    createdBy,
    name,
    previewUrl,
    experiment,
  } = selectExperience(state, id);
  const createdByUser = selectUser(state, createdBy);
  const updatedByUser = selectUser(state, updatedBy);

  return {
    appId,
    id,
    name,
    state: experienceState,
    published,
    createdAt,
    createdByName: createdByUser?.fullname ?? createdBy,
    updatedAt,
    updatedByName: updatedByUser?.fullname ?? updatedBy,
    previewUrl,
    experiment,
    schedule: selectSchedule(state),
    history: selectExperienceHistory(state, id),
  };
};

export default connect(mapStateToProps)(MobileHeader);
