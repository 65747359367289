/* global APPCUES_TEST_MODE_LAMBDA_URL */
import React from 'react';
import PropTypes from 'prop-types';
import {
  MenuSection,
  MoreMenu,
  ListRow,
  ExternalLink,
} from '@studio/legacy-components';
import { Link } from 'react-router-dom';
import { TestMode, Permalink } from '@studio/settings-panels';
import PublishingActions from 'next/components/PublishingActions';
import { ContentStateShape } from 'next/lib/shapes';
import { publishedEntitlementShape } from 'next/entities/entitlements';

import SchedulingModalProvider from 'next/components/ExperienceScheduling/SchedulingModalProvider';
import { ExperimentShape } from 'next/entities/experiment';
import { OpenInBuilderManager } from 'next/components/OpenInBuilder';
import { openFlow } from 'next/lib/builder';
import useToggle from 'next/hooks/use-toggle';
import FlowPublishingManager from './FlowPublishingManager';

export default function SettingsHeaderActions({
  id,
  published,
  updatedAt,
  state,
  hasActiveExperiment,
  flow,
  canPublish,
  onTestFlowCreated,
  accountId,
  urlConditions,
  domainConditions,
  isEntitledToPublish,
  publishedEntitlement,
}) {
  const [modalState, toggleModal] = useToggle(false);
  const hasLiveExperiment =
    !flow?.state || state !== 'DRAFT'
      ? false
      : ['LIVE', 'PAUSED'].includes(flow.state.toUpperCase());

  const disabled = !canPublish || (hasLiveExperiment && published);
  const urlSections = { and: [] };

  if (urlConditions) {
    urlSections.and.push(urlConditions);
  }
  if (domainConditions) {
    urlSections.and.push(domainConditions);
  }

  return (
    <>
      <Permalink published={published} previewUrl={flow?.previewUrl} id={id} />
      <TestMode
        onLoad={onTestFlowCreated}
        flow={flow}
        testDomain={APPCUES_TEST_MODE_LAMBDA_URL}
        accountId={accountId}
        conditions={urlSections}
      />
      <FlowPublishingManager id={id} hasActiveExperiment={hasActiveExperiment}>
        {({
          hasChanges,
          onConfirmPublish,
          onConfirmDiscardChanges,
          onConfirmUnpublish,
        }) => {
          return (
            <PublishingActions
              published={published}
              updatedAt={updatedAt}
              disabled={disabled}
              hasChanges={hasChanges}
              hasActiveExperiment={hasActiveExperiment}
              onConfirmPublish={onConfirmPublish}
              onConfirmDiscardChanges={onConfirmDiscardChanges}
              onConfirmUnpublish={onConfirmUnpublish}
              isEntitledToPublish={isEntitledToPublish}
              publishedEntitlement={publishedEntitlement}
            />
          );
        }}
      </FlowPublishingManager>
      <SchedulingModalProvider
        contentId={id}
        experienceType="Flow"
        published={published}
        disabled={disabled}
      />
      <MoreMenu>
        <MenuSection>
          <ListRow
            forwardedAs={ExternalLink}
            onClick={toggleModal}
            icon={false}
          >
            Open in Builder
          </ListRow>
          <ListRow
            forwardedAs={Link}
            icon={false}
            to={`/flows/${id}/analytics`}
          >
            Analytics
          </ListRow>
        </MenuSection>
      </MoreMenu>
      <OpenInBuilderManager
        onClose={toggleModal}
        onNavigate={() => void openFlow({ flow: id, url: flow.previewUrl })}
        type="flows"
        visible={modalState}
      />
    </>
  );
}

SettingsHeaderActions.propTypes = {
  state: ContentStateShape,
  id: PropTypes.string,
  published: PropTypes.bool,
  updatedAt: PropTypes.number,
  hasActiveExperiment: PropTypes.bool,
  flow: ExperimentShape,
  canPublish: PropTypes.bool,
  onTestFlowCreated: PropTypes.func,
  accountId: PropTypes.string,
  domainConditions: PropTypes.arrayOf(PropTypes.string),
  urlConditions: PropTypes.arrayOf(PropTypes.string),
  isEntitledToPublish: PropTypes.bool,
  publishedEntitlement: publishedEntitlementShape,
};
