/* global MOBILE_BUILDER_URL */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  MenuSection,
  MoreMenu,
  ListRow,
  ExternalLink,
} from '@studio/legacy-components';
import PublishingActions from 'next/components/PublishingActions';
import { ContentStateShape } from 'next/lib/shapes';

import SchedulingModalProvider from 'next/components/ExperienceScheduling/SchedulingModalProvider';
import { publishedEntitlementShape } from 'next/entities/entitlements';
import { ExperimentShape } from 'next/entities/experiment';
import MobilePublishingManager from './MobilePublishingManager';

export default function SettingsHeaderActions({
  accountId,
  id,
  published,
  updatedAt,
  state,
  hasActiveExperiment,
  experiment,
  canPublish,
  publishedEntitlement,
  isEntitledToPublish,
}) {
  const builderUrl = `${MOBILE_BUILDER_URL}/flows/${id}?account=${accountId}`;
  const builderPreviewUrl = `${builderUrl}&preview=true`;

  const hasLiveExperiment = useMemo(() => {
    if (!experiment?.state || state !== 'DRAFT') return false;

    return ['LIVE', 'PAUSED'].includes(experiment.state.toUpperCase());
  }, [experiment?.state, state]);

  const disabled = !canPublish || (hasLiveExperiment && published);

  return (
    <>
      <MobilePublishingManager
        id={id}
        hasActiveExperiment={hasActiveExperiment}
      >
        {({
          hasChanges,
          onConfirmPublish,
          onConfirmDiscardChanges,
          onConfirmUnpublish,
        }) => (
          <PublishingActions
            published={published}
            updatedAt={updatedAt}
            disabled={disabled}
            hasChanges={hasChanges}
            hasActiveExperiment={hasActiveExperiment}
            onConfirmPublish={onConfirmPublish}
            onConfirmDiscardChanges={onConfirmDiscardChanges}
            onConfirmUnpublish={onConfirmUnpublish}
            publishedEntitlement={publishedEntitlement}
            isEntitledToPublish={isEntitledToPublish}
            type="mobile"
          />
        )}
      </MobilePublishingManager>
      <SchedulingModalProvider
        contentId={id}
        experienceType="Mobile Experience"
        published={published}
        disabled={disabled}
      />
      <MoreMenu>
        <MenuSection>
          <ListRow forwardedAs={ExternalLink} href={builderUrl} icon={false}>
            Open in Builder
          </ListRow>
          <ListRow
            forwardedAs={ExternalLink}
            href={builderPreviewUrl}
            icon={false}
          >
            View preview options
          </ListRow>
          {state === 'PUBLISHED' && <ListRow>Open permalink options</ListRow>}
        </MenuSection>
      </MoreMenu>
    </>
  );
}

SettingsHeaderActions.propTypes = {
  accountId: PropTypes.string,
  state: ContentStateShape,
  id: PropTypes.string,
  published: PropTypes.bool,
  updatedAt: PropTypes.number,
  hasActiveExperiment: PropTypes.bool,
  experiment: ExperimentShape,
  canPublish: PropTypes.bool,
  publishedEntitlement: publishedEntitlementShape,
  isEntitledToPublish: PropTypes.bool,
};
