/* eslint-disable @appcues/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Link,
  ListRow,
  MenuSection,
  MoreMenu,
} from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import { TagsModal } from 'next/components/Tags';
import {
  selectEntitlementsByName,
  ENTITLEMENTS,
} from 'next/entities/entitlements';

export function FlowsMoreMenu({ hasPriorityAccess = true }) {
  const [visible, toggle] = useToggle();

  return (
    <>
      <MoreMenu>
        <MenuSection>
          <ListRow
            disabled={!hasPriorityAccess}
            forwardedAs={Link}
            {...(hasPriorityAccess ? { to: '/priorities' } : {})}
          >
            Prioritize Flows
          </ListRow>

          <ListRow onClick={toggle}>Manage tags</ListRow>
        </MenuSection>
      </MoreMenu>

      <TagsModal onClose={toggle} visible={visible} />
    </>
  );
}

const mapStateToProps = state => {
  const entitlement = selectEntitlementsByName(
    state,
    ENTITLEMENTS.PRIORITY_ACCESS
  );

  const hasPriorityAccess = entitlement && entitlement.allowed_units > 0;
  return {
    hasPriorityAccess,
  };
};

FlowsMoreMenu.propTypes = {
  hasPriorityAccess: PropTypes.bool,
};

export default connect(mapStateToProps)(FlowsMoreMenu);
