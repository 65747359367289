import { connect } from 'react-redux';
import { FrequencyPanel } from '@studio/settings-panels';
import { selectAccount, update as updateAccount } from 'next/entities/account';
import { selectUser } from 'next/entities/user';
import {
  ENTITLEMENTS,
  selectEntitlementsByName,
} from 'next/entities/entitlements';

const mapStateToProps = state => {
  const { throttleEnabled, throttleContentLimit, throttleContentTimespanMs } =
    selectAccount(state);

  const entitlement = selectEntitlementsByName(
    state,
    ENTITLEMENTS.FREQUENCY_ACCESS
  );
  const user = selectUser(state);
  const { email, fullname } = user;

  return {
    throttleEnabled,
    throttleContentLimit,
    throttleContentTimespanMs,
    hasFrequencyAccess: entitlement.allowed_units > 0,
    email,
    fullname,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateAccount: delta => dispatch(updateAccount(delta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FrequencyPanel);
